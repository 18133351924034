<template>
  <div
    :class="{ 'fullscreen-overlay': fullscreen, '': !fullscreen }"
    id="webcam-ui"
  >
    <Webcam
      ref="webcam"
      @init="webcamInit"
      @clear="clear"
      @stop="stop"
      @start="start"
      @pause="pause"
      @resume="resume"
      @error="error"
      @unsupported="unsupported"
      @photoTaken="photoTakenEvent"
      :shutterEffect="fullscreen"
    />
    <slot
      :loadCameras="loadCameras"
      :setCamera="setCamera"
      :fullscreen="fullscreen"
      :deviceId="deviceId"
    />
  </div>
</template>

<style>
.fullscreen-ui {
  @apply fixed bottom-0 right-0 left-0 h-32;
}
.fullscreen-overlay {
  @apply fixed top-0 bottom-0 left-0 right-0 z-50 w-screen h-screen bg-black;
}
.camera {
  @apply text-white h-12 w-12 border-4 border-white rounded-full;
}
.camera-success {
  @apply border-green-500;
}
.camera-failed {
  @apply border-red-500;
}
.button-control {
  @apply px-2 py-2 sm:py-0;
}
.invisible {
  visibility: hidden;
}
</style>

<script>
import Webcam from "./WebCamera.vue";

export default {
  emits: [
    "clear",
    "stop",
    "start",
    "pause",
    "resume",
    "error",
    "unsupported",
    "init",
    "photoTaken",
    "fullscreen",
  ],

  components: { Webcam },

  props: {
    reloadCamerasButton: {
      type: Object,
      default: () => {
        return {
          display: false,
          text: "Reload cameras",
          css: "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        };
      },
    },
    takePhotoButton: {
      type: Object,
      default: () => {
        return {
          display: true,
          text: "Take a photo",
          css: "inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        };
      },
    },
    fullscreenButton: {
      type: Object,
      default: () => {
        return {
          display: true,
          text: "Fullscreen",
          css: "inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-500 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
        };
      },
    },
    selectCameraLabel: {
      type: String,
      default: "Select camera...",
    },
    fullscreenState: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cameras: [],
      deviceId: "",
      fullscreen: false,
      photoTaken: false,
      photoFailed: false,
    };
  },

  watch: {
    fullscreenState: {
      immediate: true,
      handler: function (newVal) {
        this.fullscreen = newVal;
      },
    },
  },

  methods: {
    async takePhoto() {
      try {
        await this.$refs.webcam.takePhoto();
        this.photoTaken = true;
        setTimeout(() => {
          this.photoTaken = false;
        }, 500);
      } catch (err) {
        this.photoFailed = true;
        setTimeout(() => {
          this.photoFailed = false;
        }, 500);
      }
    },

    loadCameras() {
      this.$refs.webcam.loadCameras();
      this.cameras = this.$refs.webcam.cameras;
    },

    webcamInit(deviceId) {
      this.deviceId = deviceId;
      this.$emit("init", this.deviceId);
    },

    setCamera() {
      this.$refs.webcam.changeCamera(
        this.deviceId === "" ? null : this.deviceId
      );
    },

    flipCamera() {
      this.loadCameras();
      // flipping camera will select the next one from the list, but on most device there will be only 2, if < 2 it will not be shown
      if (this.cameras.length > 1) {
        let currentIndex = this.cameras.findIndex(
          (el) => el.deviceId === this.deviceId
        );
        let newIndex = currentIndex + 1;
        if (newIndex >= this.cameras.length) {
          newIndex = 0;
        }

        this.deviceId = this.cameras[newIndex].deviceId;
        this.$refs.webcam.changeCamera(this.cameras[newIndex].deviceId);
      }
    },

    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$emit("fullscreen", this.fullscreen);
      if (this.fullscreen) {
        // try to fullscreen webcam ui element
        if (
          document.querySelector("#webcam-ui").requestFullscreen !== undefined
        ) {
          document.querySelector("#webcam-ui").requestFullscreen();
        } else {
          document.fullscreenElement.requestFullscreen();
        }
      } else {
        document.exitFullscreen();
      }
    },

    exit() {
      this.$refs.webcam.stop();
    },

    // emits
    clear() {
      this.$emit("clear");
    },
    stop() {
      this.$emit("stop");
    },
    start() {
      this.$emit("start");
    },
    pause() {
      this.$emit("pause");
    },
    resume() {
      this.$emit("resume");
    },
    error(err) {
      this.$emit("error", err);
    },
    unsupported(err) {
      this.$emit("unsupported", err);
    },
    photoTakenEvent({ blob, image_data_url }) {
      this.$emit("photoTaken", { blob, image_data_url });
    },
  },

  mounted() {
    this.cameras = this.$refs.webcam.cameras;

    if (this.cameras.length === 0) {
      // if no camera found, we will try to refresh cameras list each second until there is some camera
      const reloadCamInterval = setInterval(() => {
        this.loadCameras();

        if (this.cameras.length > 0) {
          clearInterval(reloadCamInterval);
          // most likely due to permission, so we init afterwards
          this.$refs.webcam.init();
        }
      }, 1000);
    }
  },

  beforeUnmount() {
    if (this.reloadCamInterval) {
      clearInterval(this.reloadCamInterval);
    }
    this.exit();
  },
};
</script>
