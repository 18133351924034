<template>
  <v-dialog
    v-model="modelValueComputed"
    :scrollable="false"
    class="camera-box"
    max-width="720px"
  >
    <v-card
      class="overflow-visible"
      style="position: relative"
      max-height="90vh"
    >
      <v-btn
        class="close-button"
        density="comfortable"
        icon="mdi-close"
        color="primary"
        @click="close"
      />
      <v-card-text class="px-2" :class="{ fullHeight: isSelectedCamera }">
        <div class="camera-box__container" :class="{ hide: !isSelectedCamera }">
          <v-progress-circular
            v-if="isLoadingStream"
            :size="100"
            color="primary"
            indeterminate
          />
          <v-icon
            v-if="isStreamFailed"
            color="primary"
            icon="mdi-access-point-network-off"
            :size="100"
          />
          <tempalte v-if="modelValue">
            <iframe
              ref="frame"
              id="iframeWindow"
              :src="`/video.html?link=http://${selectedCamera?.ip_address}:7777/mjpeg`"
              @error="onFrameError"
              @load="onLoadFrame"
            />
          </tempalte>
        </div>
        <v-sheet v-if="!isSelectedCamera" class="camera-box__message">
          <v-alert v-show="!isAwaitingPhoto" type="info">
            {{ $t("imageBox.chooseDeviceMessage") }}
          </v-alert>

          <v-alert v-show="isAwaitingPhoto" type="info">
            {{ $t("imageBox.goToDeviceMessage") }}
          </v-alert>

          <v-alert v-show="isFailure" type="error">{{
            $t("imageBox.noactive")
          }}</v-alert>
        </v-sheet>
      </v-card-text>
      <v-card-actions v-if="showcontrol">
        <v-row align="center">
          <v-col cols="6">
            <v-select
              v-model="selectedCamera"
              :items="cameraList"
              item-title="name"
              item-value="id"
              :label="$t('imageBox.camera_for_reg')"
              return-object
              color="primary"
              density="compact"
              variant="outlined"
              hide-details
              :disabled="isAwaitingPhoto"
              @update:modelValue="onSelectedCamera"
            />
          </v-col>
          <v-col cols="6">
            <v-btn
              block
              large
              color="primary"
              @click="takePhoto"
              :disabled="
                !selectedCamera ||
                isAwaitingPhoto ||
                isLoadingStream ||
                isStreamFailed
              "
            >
              {{ $t("button.makePhoto") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },

    devices: {
      type: Array,
      required: true,
    },

    showcontrol: {
      type: Boolean,
      default: true,
    },

    isSelectedFirstDevice: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedCamera: undefined,

      isAwaitingPhoto: false,
      isFailure: false,

      isLoadingStream: false,
      isStreamLoaded: false,
      isStreamFailed: false,
    };
  },

  watch: {
    modelValueComputed(newValue) {
      if (newValue) {
        this.$emitter.on("takephoto", (item) => {
          this.onGetPhoto(item);
        });

        if (this.isSelectedFirstDevice && this.devices?.length) {
          this.firstSelectDevice();
        }
      } else {
        this.$emitter.off("takephoto");
      }
    },
  },

  methods: {
    close() {
      this.$emit("update:modelValue", false);
    },

    takePhoto() {
      this.isAwaitingPhoto = true;
      this.isFailure = false;

      this.axios.put(`device/${this.selectedCamera.id}/takePhoto`).catch(() => {
        this.isAwaitingPhoto = false;
        this.isFailure = true;
      });
    },

    onGetPhoto(item) {
      this.isAwaitingPhoto = false;

      this.$emit("image", `data:image/jpeg;base64,${item.image}`);

      this.close();
    },

    firstSelectDevice() {
      this.setDefaultDevice();
      const localDevice = localStorage.getItem("selectedCamera");
      if (localDevice && this.isSelectedFirstDevice) {
        let device = null;
        this.devices.forEach((deviceItem) => {
          if (deviceItem.ip_address == localDevice) {
            device = deviceItem;
          }
        });
        if (device) {
          this.selectedCamera = device;
        }
      }

      this.onSelectedCamera();
    },

    setDefaultDevice() {
      this.selectedCamera = this.devices[0];
    },

    onSelectedCamera() {
      this.isStreamFailed = false;
      this.isLoadingStream = true;
      this.isStreamLoaded = false;
      localStorage.setItem("selectedCamera", this.selectedCamera.ip_address);

      if (
        (this.selectedCamera.device_type === "" ||
          this.selectedCamera.device_type === "OGATE") &&
        this.$refs.frame
      ) {
        this.$refs.frame.setAttribute(
          "src",
          `/video.html?link=http://${this.selectedCamera.ip_address}:7777/mjpeg`
        );
      }
    },

    onFrameError() {
      this.$toast.error(this.$t("message.errorLoading"));
      this.isLoadingStream = false;
      this.isStreamFailed = true;
    },

    onLoadFrame() {
      const frame =
        this.$refs.frame.contentWindow.document.getElementById("img");
      frame.addEventListener("load", () => {
        this.onLoadStream();
      });
      frame.addEventListener("error", () => {
        this.isLoadingStream = false;
        this.isStreamFailed = true;
      });
    },

    onLoadStream() {
      this.isLoadingStream = false;
      this.isStreamLoaded = true;
    },

    recalcTimestamp() {
      setInterval(() => {
        this.timestamp = this.$moment().unix();
      }, 5000);
    },
  },

  computed: {
    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },

    cameraList() {
      return this.devices.filter((device) => device.purpose != "off");
    },
    isSelectedCamera() {
      return (
        this.selectedCamera &&
        (this.selectedCamera.device_type === "" ||
          this.selectedCamera.device_type === "OGATE")
      );
    },
  },
};
</script>
