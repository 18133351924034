<template>
  <v-dialog v-model="modelValueComputed" max-width="600">
    <v-card
      :loading="isPendingGetDepartmentsTreeState"
      :disabled="isPendingGetDepartmentsTreeState"
      :title="$t('users.setDepartment')"
    >
      <template #loader="{ isActive }">
        <v-progress-linear
          v-if="isActive"
          color="primary"
          height="10"
          indeterminate
        />
      </template>

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="profileType"
              :items="profileTypes"
              :label="$t('object.profile-types')"
              :placeholder="$t('object.all-profiles')"
              item-title="name"
              item-value="id"
              color="primary"
              variant="outlined"
              density="comfortable"
              hide-details="auto"
              multiple
              clearable
              chips
              closable-chips
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <el-tree
              ref="changeDepartmentTree"
              :data="departmentsTree"
              :props="{ label: 'name' }"
              :expand-on-click-node="false"
              :default-checked-keys="department"
              node-key="id"
              check-on-click-node
              check-strictly
              show-checkbox
              @check-change="onChangeDepartment"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-6">
        <v-row class="ma-n1">
          <v-col class="pa-1" cols="auto">
            <v-btn variant="text" @click="closeModal">
              <v-icon start>mdi-close</v-icon>
              {{ $t("button.close") }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col class="pa-1" cols="auto">
            <v-btn
              color="success"
              variant="text"
              @click="onMultiSetDepartment('add')"
              :loading="isPendingSetObjectDepartmentState"
            >
              {{ $t("button.accept") }}
              <v-icon end>mdi-check</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SetDepartmentDialog",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      department: [],
      departmentsTree: [],
      profileType: [],
      profileTypes: [],
    };
  },

  watch: {
    modelValue(newValue) {
      if (newValue) {
        this.onInitModal();
      } else {
        this.onCloseModal();
      }
    },
  },

  methods: {
    ...mapActions({
      resetGetDepartmentsTreeAction: "departments/resetGetDepartmentsTree",
      getDepartmentsTreeAction: "departments/getDepartmentsTree",

      resetSetObjectDepartmentAction: "object/resetSetObjectDepartment",
      setObjectDepartmentAction: "object/setObjectDepartment",

      getProfileTypesAction: "profiles/getProfilesTypes",
      resetGetProfileTypesAction: "profiles/resetGetProfilesTypes",
    }),

    getProfileTypes() {
      this.getProfileTypesAction().then(() => {
        this.profileTypes = this.profilesTypesState;
      });
    },

    onInitModal() {
      this.getDepartmentsTree();
      this.getProfileTypes();
    },

    onCloseModal() {
      this.department = [];
      this.profileType = [];
      this.resetSetObjectDepartmentAction();
      this.resetGetDepartmentsTreeAction();
      this.resetGetProfileTypesAction();
    },

    closeModal() {
      this.$emit("update:modelValue", false);
    },

    getDepartmentsTree() {
      this.getDepartmentsTreeAction().then(() => {
        this.departmentsTree = structuredClone(this.departmentsTreeState);
      });
    },

    onChangeDepartment(element, state) {
      if (state) this.$refs.changeDepartmentTree.setCheckedKeys([element.id]);

      this.department = this.$refs.changeDepartmentTree.getCheckedKeys();
    },

    async onMultiSetDepartment(action) {
      const curParams = {
        department: this.department[0],
        profiles: this.profileType,
        action,
      };

      curParams.filter = { ids: this.selectedIds, ...this.filter };

      this.setObjectDepartmentAction(curParams).then(() => {
        this.department = [];
        this.closeModal();
      });
    },
  },

  computed: {
    ...mapState({
      isPendingGetDepartmentsTreeState: (state) =>
        state.departments.isPendingGetDepartmentsTree,
      isFailureGetDepartmentsTreeState: (state) =>
        state.departments.isFailureGetDepartmentsTree,
      errorMessageGetDepartmentsTreeState: (state) =>
        state.departments.errorMessageGetDepartmentsTree,

      departmentsTreeState: (state) => state.departments.departmentsTree,

      isPendingSetObjectDepartmentState: (state) =>
        state.object.isPendingSetObjectDepartment,

      profilesTypesState: (state) => state.profiles.profilesTypes,
    }),

    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
