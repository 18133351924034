<template>
  <v-btn
    v-if="!isHasCabinet"
    :disabled="isPendingCreateObjectCabinetState"
    color="success"
    flat
    @click="createObjectCabinet"
    >{{ $t("object.object-cabinet-create") }}</v-btn
  >
  <v-btn
    v-else
    :disabled="isPendingDeleteObjectCabinetState"
    color="error"
    flat
    @click="deleteObjectCabinet"
    >{{ $t("object.object-cabinet-delete") }}</v-btn
  >
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  emits: ["success"],

  props: {
    isHasCabinet: {
      type: Boolean,
      default: false,
    },

    objectId: {
      type: [String, Number],
      default: "",
    },
  },

  methods: {
    ...mapActions({
      resetCreateObjectCabinetAction: "object/resetCreateObjectCabinet",
      createObjectCabinetAction: "object/createObjectCabinet",

      resetDeleteObjectCabinetAction: "object/resetDeleteObjectCabinet",
      deleteObjectCabinetAction: "object/deleteObjectCabinet",
    }),

    createObjectCabinet() {
      this.createObjectCabinetAction(this.objectId).then(() =>
        this.$emit("success")
      );
    },

    deleteObjectCabinet() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: () => {
          this.deleteObjectCabinetAction(this.objectId).then(() =>
            this.$emit("success")
          );
        },
      });
    },
  },

  computed: {
    ...mapState({
      isPendingCreateObjectCabinetState: (state) =>
        state.object.isPendingCreateObjectCabinet,

      isPendingDeleteObjectCabinetState: (state) =>
        state.object.isPendingDeleteObjectCabinet,
    }),
  },

  unmounted() {
    this.resetCreateObjectCabinetAction();
    this.resetDeleteObjectCabinetAction();
  },
};
</script>
