<template>
  <div class="mt-5">
    <v-row v-if="errorMessageSaveSettingsData">
      <v-col>
        <Alert type="error">
          {{ errorMessageSaveSettingsData }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageSettingsData">
      <v-col>
        <Alert type="error">
          {{ errorMessageSettingsData }}
        </Alert>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          v-model="companyName"
          :label="`${$t('setting.companyName')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />
        <div class="text-right">
          <v-btn color="primary" @click="storeCompanyName">
            {{ $t("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col cols="12">
        <h3 class="mb-3">{{ $t("setting.invitations") }}</h3>
        <v-text-field
          v-model="url"
          :label="`${$t('setting.url')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-text-field
          v-model="emailInviteTitle"
          :label="`${$t('setting.emailTitle')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-textarea
          v-model="emailInviteMessage"
          :label="`${$t('setting.emailMessage')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-textarea
          v-model="smsInviteMessage"
          :label="`${$t('setting.smsMessage')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <div class="text-right">
          <v-btn color="primary" @click="storeInvitations">
            {{ $t("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col cols="12">
        <h3 class="mb-3">{{ $t("setting.KioskRegistration") }}</h3>
        <v-text-field
          v-model="emailKioskTitle"
          :label="`${$t('setting.emailTitle')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-textarea
          v-model="emailKioskMessage"
          :label="`${$t('setting.emailMessage')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-textarea
          v-model="smsKioskMessage"
          :label="`${$t('setting.smsMessage')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <div class="text-right">
          <v-btn color="primary" @click="storeKiosk">
            {{ $t("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-divider class="my-5" />

    <v-row>
      <v-col cols="12">
        <h3 class="mb-3">{{ $t("setting.changeAccess") }}</h3>
        <v-text-field
          v-model="emailTitle"
          :label="`${$t('setting.emailTitle')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-textarea
          v-model="emailMessage"
          :label="`${$t('setting.emailMessage')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <v-textarea
          v-model="smsMessage"
          :label="`${$t('setting.smsMessage')}`"
          variant="outlined"
          density="comfortable"
          color="primary"
        />

        <div class="text-right">
          <v-btn color="primary" @click="storeAccess">
            {{ $t("button.save") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

import Alert from "@/components/Alert";

export default {
  components: {
    Alert,
  },

  data() {
    return {
      url: "",
      emailMessage:
        "Вам предоставлен доступ в личный кабинет<br>\nВаш Логин: [USERNAME]<br>\nВаш пароль: [PASSWORD]",
      emailTitle: "Предоставление доступа в личный кабинет",
      emailInviteMessage: "Перейдите по ссылке приглашения: [LINK]",
      emailInviteTitle: "Подтвердите приглашение",
      smsInviteMessage: "Перейдите по ссылке приглашения: [LINK]",
      emailKioskMessage:
        "Введите код [PIN] в приложение киоска и пройдите регистрацию",
      emailKioskTitle: "Код для регистрации через киоск",
      smsKioskMessage:
        "Введите код [PIN] в приложение киоска и пройдите регистрацию",
      smsMessage:
        "Вам предоставлен доступ в личный кабинет\nВаш Логин: [USERNAME]\nВаш пароль: [PASSWORD]",
      companyName: "OVISION",
      settings: [],
    };
  },

  methods: {
    ...mapActions({
      setSettings: "settings/setSettings",
      getSettings: "settings/getSettings",
    }),
    storeCompanyName() {
      const curData = [
        {
          key: "COMPANY_NAME",
          value: this.companyName,
        },
      ];

      this.setSettings(curData);
    },
    storeInvitations() {
      const curData = [
        {
          key: "LANDING_URL",
          value: this.url,
        },
        {
          key: "EMAIL_INVITE_TITLE",
          value: this.emailInviteTitle,
        },
        {
          key: "EMAIL_INVITE_MESSAGE",
          value: this.emailInviteMessage,
        },
        {
          key: "SMS_INVITE_MESSAGE",
          value: this.smsInviteMessage,
        },
      ];

      this.setSettings(curData);
    },
    storeKiosk() {
      const curData = [
        {
          key: "EMAIL_KIOSK_TITLE",
          value: this.emailKioskTitle,
        },
        {
          key: "EMAIL_KIOSK_MESSAGE",
          value: this.emailKioskMessage,
        },
        {
          key: "SMS_KIOSK_MESSAGE",
          value: this.smsKioskMessage,
        },
      ];

      this.setSettings(curData);
    },
    storeAccess() {
      const curData = [
        {
          key: "EMAIL_MESSAGE",
          value: this.emailMessage,
        },
        {
          key: "EMAIL_TITLE",
          value: this.emailTitle,
        },
        {
          key: "SMS_MESSAGE",
          value: this.smsMessage,
        },
      ];

      this.setSettings(curData);
    },
    load() {
      this.url = this.settings.LANDING_URL || "";
      this.companyName = this.settings.COMPANY_NAME || "OVISION";
      this.emailInviteMessage =
        this.settings.EMAIL_INVITE_MESSAGE ||
        "Перейдите по ссылке приглашения: [LINK]";
      this.emailInviteTitle =
        this.settings.EMAIL_INVITE_TITLE || "Подтвердите приглашение";
      this.smsInviteMessage =
        this.settings.SMS_INVITE_MESSAGE ||
        "Перейдите по ссылке приглашения: [LINK]";
      this.emailKioskMessage =
        this.settings.EMAIL_KIOSK_MESSAGE ||
        "Введите код [PIN] в приложение киоска и пройдите регистрацию";
      this.emailKioskTitle =
        this.settings.EMAIL_KIOSK_TITLE || "Код для регистрации через киоск";
      this.smsKioskMessage =
        this.settings.SMS_KIOSK_MESSAGE ||
        "Введите код [PIN] в приложение киоска и пройдите регистрацию";

      this.emailMessage =
        this.settings.EMAIL_MESSAGE ||
        "Вам предоставлен доступ в личный кабинет<br>\nВаш Логин: [USERNAME]<br>\nВаш пароль: [PASSWORD]";
      this.emailTitle =
        this.settings.EMAIL_TITLE || "Предоставление доступа в личный кабинет";
      this.smsMessage =
        this.settings.SMS_MESSAGE ||
        "Вам предоставлен доступ в личный кабинет\nВаш Логин: [USERNAME]\nВаш пароль: [PASSWORD]";
    },
  },

  computed: {
    ...mapState({
      stateSettings: (state) => state.settings.settingsData,
      errorMessageSaveSettingsData: (state) =>
        state.settings.errorMessageSaveSettingsData,
      errorMessageSettingsData: (state) =>
        state.settings.errorMessageSettingsData,
    }),
  },
  mounted() {
    this.getSettings();
  },
  watch: {
    stateSettings() {
      this.settings = this.stateSettings;
      this.load();
    },
  },
};
</script>
