<template>
  <v-checkbox
    :validation-value="fieldComputed"
    class="v-checkbox-phone-custom-fields--hidden"
    hide-details="auto"
    :rules="phoneRules"
    :error="checkPhoneDataError != ''"
    :error-messages="checkPhoneDataError ? [checkPhoneDataError] : ''"
  >
    <template #append="{ isValid }">
      <MazPhoneNumberInput
        v-model="fieldComputed"
        v-model:country-code="countryCode"
        :translations="{
          countrySelector: {
            placeholder: $t('telInput.selectorPlaceholder'),
            error: $t('telInput.error'),
            searchPlaceholder: $t('telInput.searchPlaceholder'),
          },
          phoneInput: {
            placeholder: $t('telInput.inputPlaceholder'),
            example: $t('telInput.example'),
          },
        }"
        :error="isValid && isValid.value != null && !isValid.value"
        :noFormattingAsYouType="false"
        :preferredCountries="[
          'RU',
          'BY',
          'KZ',
          'MD',
          'AM',
          'AZ',
          'GE',
          'KG',
          'TJ',
          'TM',
          'UZ',
        ]"
        :countryLocale="$i18n.locale"
        :noValidationSuccess="true"
        :noValidationError="true"
      />
    </template>
    <template #message="{ message }">
      <div v-html="message" />
    </template>
  </v-checkbox>
</template>
<script>
import { mapActions, mapState } from "vuex";
import debounce from "lodash.debounce";
import parsePhoneNumber from "libphonenumber-js";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    settings: {
      type: Object,
      default: () => {},
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      validPhone: false,
      countryCode: "",
    };
  },

  methods: {
    ...mapActions({
      checkPhone: "persons/checkPhone",
      resetCheckPhone: "persons/resetCheckPhone",
    }),

    checkPhoneDebounced: debounce(function (phone) {
      this.checkPhone({ id: this.settings.object_id, phone });
    }, 300),
  },

  computed: {
    ...mapState({
      checkPhoneData: (state) => state.persons.checkPhoneData,
    }),

    checkPhoneDataError() {
      if (this.checkPhoneData.result && this.settings.isObject) {
        const route = this.$router.resolve({
          name: this.$const.ROUTES.OBJECT_SHOW,
          params: {
            id: this.checkPhoneData.person_id,
          },
        });

        return this.$t("requests.phoneExist", {
          href: route.href,
        });
      } else {
        return "";
      }
    },

    fieldComputed: {
      get() {
        const curValue = this.modelValue.find(
          (value) => value.name == this.field.name
        );

        return curValue?.value;
      },
      set(newValue) {
        let isEdit = false;

        const curValues = this.modelValue.map((value) => {
          const curValue = { ...value };

          if (value.name == this.field.name) {
            curValue.value = newValue;
            isEdit = true;
          }

          return curValue;
        });

        if (!isEdit) {
          curValues.push({
            type: this.field.type,
            name: this.field.name,
            value: newValue,
          });
        }

        this.$emit("update:modelValue", curValues);
      },
    },

    phoneRules() {
      return [
        (v) => {
          this.resetCheckPhone();

          if (this.field.required && !v) {
            return this.$t("object-type.errSelectItemRequire");
          }

          const parsePhone = parsePhoneNumber(v, this.countryCode);

          if (v && parsePhone && !parsePhone.isValid()) {
            return this.$t("user.notPhone");
          }

          if (this.settings.isObject) {
            this.checkPhoneDebounced(v);
          }

          if (this.checkPhoneDataError != "") {
            return this.checkPhoneDataError;
          }

          return true;
        },
      ];
    },
  },

  unmounted() {
    this.resetCheckPhone();
  },
};
</script>
