<template>
  <v-text-field
    v-model="fieldComputed"
    ref="email"
    density="comfortable"
    color="primary"
    variant="outlined"
    :rules="emailRules"
    :label="$t('user.email')"
    hide-details="auto"
    :error="checkEmailDataError != ''"
    :error-messages="checkEmailDataError ? [checkEmailDataError] : ''"
  >
    <template #message="{ message }">
      <span v-html="message" />
    </template>
  </v-text-field>
</template>
<script>
import { isEmail } from "validator";
import { mapState, mapActions } from "vuex";
import debounce from "lodash.debounce";

export default {
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },

    settings: {
      type: Object,
      default: () => {},
    },

    field: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      emailRules: [
        (v) => {
          this.resetCheckEmail();

          if (this.field.required && !v) {
            return this.$t("requests.oneOfContactIsRequired");
          }

          if (v && !isEmail(v)) {
            return this.$t("user.email_char_valid");
          }

          if (this.settings.isObject) {
            this.onChangeEmail(v);
          }

          if (this.checkEmailDataError != "") {
            return this.checkEmailDataError;
          }

          return true;
        },
      ],
    };
  },

  methods: {
    ...mapActions({
      checkEmail: "persons/checkEmail",
      resetCheckEmail: "persons/resetCheckEmail",
    }),

    onChangeEmail: debounce(async function (email) {
      await this.checkEmail({ id: this.settings.object_id, email });
    }, 300),
  },

  computed: {
    ...mapState({
      checkEmailData: (state) => state.persons.checkEmailData,
    }),

    checkEmailDataError() {
      if (this.checkEmailData.result && this.settings.isObject) {
        const route = this.$router.resolve({
          name: this.$const.ROUTES.OBJECT_SHOW,
          params: {
            id: this.checkEmailData.person_id,
          },
        });

        return this.$t("requests.emailExist", {
          href: route.href,
        });
      } else {
        return "";
      }
    },

    fieldComputed: {
      get() {
        const curValue = this.modelValue.find(
          (value) => value.name == this.field.name
        );

        return curValue?.value;
      },
      set(newValue) {
        let isEdit = false;

        const curValues = this.modelValue.map((value) => {
          const curValue = { ...value };

          if (value.name == this.field.name) {
            curValue.value = newValue;
            isEdit = true;
          }

          return curValue;
        });

        if (!isEdit) {
          curValues.push({
            type: this.field.type,
            name: this.field.name,
            value: newValue,
          });
        }

        this.$emit("update:modelValue", curValues);
      },
    },
  },

  unmounted() {
    this.resetCheckEmail();
  },
};
</script>
