export const reservedObjectFieldTypes = [
  "res_phone",
  "res_email",
  "res_tab_num",
];

export const reservedProfileFieldTypes = ["res_invite_mes"];

export const reservedObjectFieldNames = ["phone", "email", "tab_num"];

export default {
  data() {
    return {
      reservedObjectFieldTypes,
      reservedObjectFieldNames,
      reservedProfileFieldTypes,
    };
  },
};
