<template>
  <v-row>
    <Filter
      v-model="filter"
      :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
      :md="!pageData.isMini ? 3 : 12"
      filterName="zones"
      @toggleMini="toggleMini"
      @update:modelValue="onFilter"
    />
    <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
      <v-row v-if="isFailureGetZonesTreeState">
        <v-col>
          <Alert type="error">
            {{ errorMessageGetZonesTreeState }}
          </Alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-skeleton-loader
            v-if="isPendingGetZonesTreeState"
            class="mx-auto"
            type="list-item@3"
          />

          <el-tree
            v-else
            ref="zonesTree"
            :data="zonesTree"
            :props="{ label: 'name' }"
            default-expand-all
            :expand-on-click-node="false"
          >
            <template #="{ data }">
              <v-tooltip v-if="can($const.RULES.ZONE_DELETE)" location="top">
                <template #activator="{ props }">
                  <v-btn
                    v-bind="props"
                    density="compact"
                    variant="text"
                    icon
                    color="red"
                    @click="onRemove(data.id)"
                  >
                    <v-icon size="x-small">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("access-levels.delete") }}</span>
              </v-tooltip>
              <router-link
                v-if="can($const.RULES.ZONES_EDIT)"
                class="text-primary"
                :to="{
                  name: $const.ROUTES.EDIT_ZONE,
                  params: { id: data.id },
                }"
              >
                {{ data.name }}
              </router-link>
              <template v-else>
                {{ data.name }}
              </template>
            </template>
          </el-tree>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";

import Alert from "@/components/Alert";
import Filter from "@/components/Filter";

import permissions from "@/mixins/permissions";

export default {
  name: "ZonesTree",

  mixins: [permissions],

  components: { Alert, Filter },

  data() {
    return {
      zonesTree: [],

      pageData: {},
      filter: {},
      pagination: {},
    };
  },

  methods: {
    ...mapActions({
      getZonesTreeAction: "zones/getZonesTree",

      removeZoneAction: "zone/remove",

      setStatePageDataAction: "stateZones/setPageData",
      setStateFilterAction: "stateZones/setFilter",
      setStatePaginationAction: "stateZones/setPagination",
    }),

    getZonesTree() {
      const curParams = {
        ...this.filter,
      };

      this.getZonesTreeAction(curParams).then(() => {
        this.zonesTree = this.zonesTreeState;
      });
    },

    onRemove(id) {
      if (!id) return;

      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.removeZoneAction(id);
          this.getZonesTree();
        },
      });
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния страницы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },
    //Запись состояния страницы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },
    setFilterState() {
      this.setStateFilterAction(this.filter);
    },
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },
    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getZonesTree();
    },
    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },
  },

  computed: {
    ...mapState({
      isPendingGetZonesTreeState: (state) => state.zones.isPendingGetZonesTree,
      isFailureGetZonesTreeState: (state) => state.zones.isFailureGetZonesTree,
      errorMessageGetZonesTreeState: (state) =>
        state.zones.errorMessageGetZonesTree,

      zonesTreeState: (state) => state.zones.zonesTree,

      statePageData: (state) => state.stateZones.data,
      stateFilter: (state) => state.stateZones.filter,
      statePagination: (state) => state.stateZones.pagination,
    }),
  },

  created() {
    this.getState();
    this.getZonesTree();
  },
};
</script>
