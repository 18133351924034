<template>
  <div>
    <v-row v-if="errorMessage">
      <v-col>
        <Alert type="error">
          {{ errorMessage }}
        </Alert>
      </v-col>
    </v-row>

    <v-row v-if="errorMessageDelete">
      <v-col>
        <Alert type="error">
          {{ errorMessageDelete }}
        </Alert>
      </v-col>
    </v-row>

    <CameraBox
      v-model="isCameraShow"
      :devices="cameraShowDevices"
      :showcontrol="false"
      isSelectedFirstDevice
    />

    <v-row>
      <Filter
        v-model="filter"
        :filterType="!pageData.isMini ? 'default' : 'defaultHorizontal'"
        :md="!pageData.isMini ? 3 : 12"
        filterName="devices"
        @toggleMini="toggleMini"
        @update:modelValue="onFilter"
      />

      <v-col cols="12" :lg="!pageData.isMini ? 9 : 12">
        <v-row>
          <v-col
            v-if="can(this.$const.RULES.DEVICE_ACTIONS)"
            cols="12"
            md="auto"
          >
            <v-menu offset-y>
              <template #activator="{ props }">
                <v-btn v-bind="props" :disabled="!getSelectDevices.length">
                  {{ $t("menu.actions") }}&nbsp;
                  <v-icon end> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list density="compact">
                <v-list-item @click="onEmergency('open')">
                  <v-list-item-title>{{
                    $t("devices.emergency.open")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onEmergency('default')">
                  <v-list-item-title>{{
                    $t("devices.emergency.close")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item @click="onBlocked('off')">
                  <v-list-item-title>{{
                    $t("devices.ogatestate.off")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onBlocked('on')">
                  <v-list-item-title>{{
                    $t("devices.ogatestate.on")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item @click="onDisabledBiometricStatus(true)">
                  <v-list-item-title>{{
                    $t("devices.biometric.off")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onDisabledBiometricStatus(false)">
                  <v-list-item-title>{{
                    $t("devices.biometric.on")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item @click="onReboot">
                  <v-list-item-title>{{
                    $t("devices.reboot")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onSetDefault">
                  <v-list-item-title>{{
                    $t("devices.toDefault")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item @click="onChangeAutoSync()">
                  <v-list-item-title>{{
                    $t("devices.autosync.on")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onChangeAutoSync(true)">
                  <v-list-item-title>{{
                    $t("devices.autosync.off")
                  }}</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item @click="onDelete">
                  <v-list-item-title>{{
                    $t("devices.delete")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
          <v-spacer />
          <!-- <v-col class="flex-grow-0">
            <DeviceStatistics :devices="devices" />
          </v-col> -->
          <v-col v-if="can($const.RULES.DEVICE_ADD)" cols="12" md="auto">
            <v-menu>
              <template #activator="{ props }">
                <v-btn color="primary" v-bind="props">
                  <v-icon start> mdi-plus </v-icon>
                  {{ $t("devices.addDevice") }}&nbsp;
                  <v-icon end> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list density="compact">
                <v-list-item
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'OGATE' },
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceOgate")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'OMINI' },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceOmini")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'UFACE' },
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceUface")
                  }}</v-list-item-title>
                </v-list-item>
                <v-list-item
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'CONTRL' },
                    params: {
                      query: $route.query,
                    },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceController")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'AUTOMARSHAL' },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceAutomarshal")
                  }}</v-list-item-title>
                </v-list-item>

                <v-list-item
                  :to="{
                    name: $const.ROUTES.ADD_DEVICE,
                    query: { device: 'ROBOSTRAZH' },
                  }"
                >
                  <v-list-item-title>{{
                    $t("devices.addNewDeviceRobostrazh")
                  }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
              ref="table"
              class="v-data-table__rows--relative"
              v-model="selected"
              :loading="isPending"
              :headers="headers"
              :items="devicesState"
              :items-per-page="pagination.limit"
              show-select
              color="primary"
              disable-sort
            >
              <template #body />
              <template #tbody="{ internalItems }">
                <vue-draggable-plus
                  v-if="$refs.table"
                  tag="tbody"
                  v-model="devicesState"
                  handle=".drag-item"
                  @end="onDragEnd"
                >
                  <v-data-table-rows
                    :items="internalItems"
                    :rowProps="rowProps"
                  >
                    <template #[`item.drag`]>
                      <v-icon
                        v-if="!isPendingSort && !isPending"
                        class="drag-item"
                        @click.stop
                      >
                        mdi-drag-horizontal-variant
                      </v-icon>
                    </template>

                    <template #[`item.avatar`]="{ item }">
                      <RadialProgress
                        :size="78"
                        :progress="radialProgressStyle(item)"
                        class="mt-3 mb-3"
                        :progressColor="
                          syncDeviceData[item.id]?.sync_lock ? 2 : 1
                        "
                      >
                        <v-badge
                          :model-value="syncDeviceData[item.id] ? true : false"
                          :content="
                            syncDeviceData[item.id]
                              ? syncDeviceData[item.id].personNum
                              : 0
                          "
                          bordered
                          color="primary"
                          class="pt-2 pb-2"
                          offset-x="7"
                          offset-y="7"
                        >
                          <v-avatar size="70">
                            <v-img :src="item.avatar" :alt="item.name" />
                          </v-avatar>
                        </v-badge>
                      </RadialProgress>
                    </template>

                    <template #[`item.status`]="{ item }">
                      <v-chip
                        v-if="!item.isEnabled"
                        size="x-small"
                        color="grey"
                        class="mr-1 my-1"
                      >
                        <v-icon start size="x-small">mdi-close-circle</v-icon>
                        {{ $t("devices.disabled") }}
                      </v-chip>
                      <template v-else>
                        <div v-if="syncDeviceData[item.id]">
                          <v-chip
                            v-if="!syncDeviceData[item.id].status"
                            size="x-small"
                            color="grey darken-3"
                            text-color="white"
                            class="mr-1 my-1"
                          >
                            <v-icon start size="x-small"
                              >mdi-access-point-network-off</v-icon
                            >
                            Offline
                          </v-chip>

                          <v-chip
                            v-else
                            size="x-small"
                            :color="
                              syncDeviceData[item.id]?.sync_lock
                                ? '#473AD0'
                                : 'green'
                            "
                            text-color="white"
                            class="mr-1 my-1"
                          >
                            <v-icon start size="x-small"
                              >mdi-access-point-network</v-icon
                            >
                            Online
                          </v-chip>

                          <v-chip
                            v-if="!syncDeviceData[item.id].sync_lock"
                            size="x-small"
                            :color="
                              syncDeviceData[item.id]?.sync_lock
                                ? '#473AD0'
                                : 'green'
                            "
                            text-color="white"
                            class="mr-1 my-1"
                          >
                            <v-icon start size="x-small">mdi-autorenew</v-icon>
                            Auto
                          </v-chip>

                          <v-chip
                            v-else
                            size="x-small"
                            color="grey darken-3"
                            text-color="white"
                            class="mr-1 my-1"
                          >
                            <v-icon start size="x-small">mdi-sync-off</v-icon>
                            Lock
                          </v-chip>

                          <v-chip
                            v-if="
                              syncDeviceData[item.id].isBlock &&
                              syncDeviceData[item.id].status
                            "
                            size="x-small"
                            color="red"
                            text-color="white"
                            class="mr-1 my-1"
                          >
                            <v-icon start size="x-small">mdi-cancel</v-icon>
                            {{ $t("devices.blocked") }}
                          </v-chip>

                          <v-chip
                            v-if="
                              syncDeviceData[item.id].isBiometricDisabled &&
                              syncDeviceData[item.id].status
                            "
                            size="x-small"
                            color="purple"
                            text-color="white"
                            class="mr-1 my-1"
                          >
                            <v-icon start size="x-small"
                              >mdi-account-off</v-icon
                            >
                            {{ $t("devices.biometricDisabled") }}
                          </v-chip>
                        </div>
                        <v-skeleton-loader
                          v-else
                          class="mx-auto"
                          max-width="100"
                          type="text"
                        />
                      </template>
                    </template>

                    <template #[`item.progress`]="{ item }">
                      <div
                        class="v-data-table__row-progress-bar"
                        :class="{
                          'v-data-table__row-progress-bar--lock-sync':
                            syncDeviceData[item.id]?.sync_lock,
                          'v-data-table__row-progress-bar--disable-transition':
                            syncDeviceData[item.id]?.progressStart,
                        }"
                        :style="rowStyle(item)"
                      />
                    </template>

                    <template #[`item.doorStatus`]="{ item }">
                      <template v-if="item.isEnabled">
                        <div v-if="syncDeviceData[item.id]">
                          <div
                            v-for="(door, index) in getDoors(item)"
                            :key="index"
                            class="text-no-wrap d-flex align-center"
                          >
                            <template v-if="door.enable">
                              <v-tooltip location="top">
                                <template #activator="{ props }">
                                  <v-chip
                                    v-bind="props"
                                    size="x-small"
                                    :color="
                                      getDoorStatusColor(
                                        door.status,
                                        syncDeviceData[item.id]?.sync_lock
                                      )
                                    "
                                    text-color="white"
                                    class="mr-1 my-1"
                                  >
                                    <v-icon start size="x-small">
                                      <template
                                        v-if="door.status === 'default'"
                                      >
                                        mdi-lock
                                      </template>
                                      <template
                                        v-else-if="door.status === 'open'"
                                      >
                                        mdi-lock-open-variant
                                      </template>
                                      <template
                                        v-else-if="
                                          door.status === 'unavailable'
                                        "
                                      >
                                        mdi-close-circle
                                      </template>
                                    </v-icon>
                                    {{ door.name }}
                                  </v-chip>
                                </template>
                                <span v-if="door.status === 'default'">{{
                                  $t("device.doorClose")
                                }}</span>
                                <span v-else-if="door.status === 'open'">{{
                                  $t("device.doorOpen")
                                }}</span>
                                <span
                                  v-else-if="door.status === 'unavailable'"
                                  >{{ $t("device.doorUnavailable") }}</span
                                >
                              </v-tooltip>

                              <v-tooltip
                                v-if="door.status === 'default'"
                                location="top"
                              >
                                <template #activator="{ props }">
                                  <v-btn
                                    v-bind="props"
                                    variant="text"
                                    density="compact"
                                    icon
                                    :color="
                                      syncDeviceData[item.id]?.sync_lock
                                        ? '#473AD0'
                                        : 'green'
                                    "
                                    @click.stop="
                                      onChangeDoorStatus(item, index, 'open')
                                    "
                                  >
                                    <v-icon size="x-small">mdi-key</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("device.doorUnlock") }}</span>
                              </v-tooltip>
                              <v-tooltip
                                v-else-if="door.status === 'open'"
                                location="top"
                              >
                                <template #activator="{ props }">
                                  <v-btn
                                    v-bind="props"
                                    variant="text"
                                    density="compact"
                                    icon
                                    color="red"
                                    @click.stop="
                                      onChangeDoorStatus(item, index, 'default')
                                    "
                                  >
                                    <v-icon size="x-small">mdi-key</v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t("device.doorLock") }}</span>
                              </v-tooltip>
                            </template>
                          </div>
                        </div>
                        <v-skeleton-loader
                          v-else
                          class="mx-auto"
                          max-width="100"
                          type="text"
                        />
                      </template>
                    </template>

                    <template #[`item.name`]="{ item }">
                      <router-link
                        :class="
                          syncDeviceData[item.id]?.sync_lock
                            ? 'text--ovision-dark-blue'
                            : null
                        "
                        v-if="can($const.RULES.DEVICE_EDIT)"
                        class="text-primary"
                        :to="{
                          name: $const.ROUTES.EDIT_DEVICE,
                          params: { id: item.id, query: $route.query },
                        }"
                        >{{ item.name }}</router-link
                      >
                      <template v-else>
                        {{ item.name }}
                      </template>

                      <small
                        v-if="item.ipAddress !== item.name"
                        class="d-block"
                        :class="
                          syncDeviceData[item.id]?.sync_lock
                            ? 'text--ovision-light-midnight-express'
                            : 'blue-grey--text text--lighten-1'
                        "
                      >
                        {{ item.ipAddress }}
                      </small>

                      <small
                        class="d-block"
                        :class="
                          syncDeviceData[item.id]?.sync_lock
                            ? 'text--ovision-light-midnight-express'
                            : 'blue-grey--text text--lighten-1'
                        "
                      >
                        {{ item.deviceType }}
                      </small>
                    </template>

                    <template #[`item.purpose`]="{ item }">
                      <v-tooltip
                        v-for="(channel, index) in item.channels.filter(
                          (channel) => channel.enable
                        )"
                        :key="index"
                        location="top"
                      >
                        <template #activator="{ props }">
                          <div>
                            <v-chip
                              v-bind="props"
                              :color="
                                getPurposeColor(
                                  channel.purpose,
                                  syncDeviceData[item.id]?.sync_lock
                                )
                              "
                              variant="outlined"
                              class="my-1"
                              size="small"
                            >
                              <v-icon start size="small">
                                {{ getPurposeIcon(channel.purpose) }}
                              </v-icon>
                              {{ getPurposeFrom(channel) }}
                              <v-icon size="x-small" class="mx-1"
                                >mdi-arrow-right</v-icon
                              >
                              {{ getPurposeTo(channel) }}
                            </v-chip>
                          </div>
                        </template>
                        <span>{{
                          $t(`device.purpose_${channel.purpose}`)
                        }}</span>
                      </v-tooltip>
                    </template>

                    <template #[`item.updatedAt`]="{ item }">
                      <template v-if="item.isEnabled">
                        <template v-if="syncDeviceData[item.id]">
                          {{
                            syncDeviceData[item.id].status
                              ? $moment(syncDeviceData[item.id].time).format(
                                  "DD.MM.YYYY HH:mm"
                                )
                              : ""
                          }}
                        </template>

                        <v-skeleton-loader
                          v-else
                          class="mx-auto"
                          max-width="100"
                          type="text"
                        />
                      </template>
                    </template>

                    <template #[`item.actions`]="{ item }">
                      <v-tooltip location="top">
                        <template #activator="{ props }">
                          <v-btn
                            v-bind="props"
                            variant="text"
                            density="compact"
                            icon
                            :disabled="!item.isEnabled"
                            @click.stop="startSyncDevice(item.id)"
                          >
                            <v-icon size="x-small">mdi-sync</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("devices.syncroDevice") }}</span>
                      </v-tooltip>

                      <v-tooltip
                        v-if="can($const.RULES.DEVICE_EDIT)"
                        location="top"
                      >
                        <template #activator="{ props }">
                          <v-btn
                            v-bind="props"
                            variant="text"
                            density="compact"
                            icon
                            :color="
                              syncDeviceData[item.id]?.sync_lock
                                ? '#473AD0'
                                : 'primary'
                            "
                            @click.stop="goToEdit(item.id)"
                          >
                            <v-icon size="x-small">mdi-cog</v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t("devices.configureDevice") }}</span>
                      </v-tooltip>
                    </template>

                    <template #[`item.errorLogs`]="{ item }">
                      <v-tooltip v-if="item.isErrorLogs" location="top">
                        <template #activator="{ props }">
                          <v-btn
                            v-bind="props"
                            variant="text"
                            density="compact"
                            icon
                            color="red"
                            @click.stop="openDeviceErrorLogs(item)"
                          >
                            <v-icon size="x-small"
                              >mdi-alert-circle-outline</v-icon
                            >
                          </v-btn>
                        </template>
                        <span>{{ $t("devices.deviceErrorLogs") }}</span>
                      </v-tooltip>
                    </template>
                  </v-data-table-rows>
                </vue-draggable-plus>
              </template>

              <template #bottom>
                <DataTableFooterV2
                  v-model="pagination"
                  @update:modelValue="onPagination"
                />
              </template>
            </v-data-table>

            <v-card
              v-if="can($const.RULES.DEVICE_ADD)"
              class="mt-5 mx-auto"
              color="primary"
              variant="outlined"
              :loading="isPendingScan"
              :disabled="isPendingScan"
            >
              <v-card-actions>
                <v-btn color="primary" variant="text" @click="scanDevices">
                  <v-icon start>mdi-magnify-scan</v-icon>
                  {{ $t("devices.scan") }}
                </v-btn>
                <v-spacer />
                <v-btn
                  v-if="selectedScanDevices.length"
                  color="success"
                  variant="text"
                  @click="addSelectedDevice"
                >
                  <v-icon start>mdi-plus</v-icon>
                  {{ $t("devices.addscandevices") }}
                </v-btn>
              </v-card-actions>

              <v-card-text v-if="scannedDevices.length">
                <Alert v-if="errorMessageScan" type="error">
                  {{ errorMessageScan }}
                </Alert>

                <v-chip-group v-model="selectedScanDevices" column multiple>
                  <v-chip
                    v-for="device in scannedDevices"
                    :key="device.ip"
                    :value="device"
                    :filter="!allDevicesIp.includes(device.ip)"
                    :color="allDevicesIp.includes(device.ip) ? 'success' : ''"
                    :disabled="isDisabledScanDevice(device)"
                    variant="outlined"
                  >
                    <strong>{{ device.type }}</strong
                    >: {{ device.ip }}
                    <v-tooltip v-if="device.stream" location="top">
                      <template #activator="{ props }">
                        <v-btn
                          v-bind="props"
                          variant="text"
                          density="compact"
                          icon
                          :color="
                            allDevicesIp.includes(device.ip) ? 'success' : ''
                          "
                          @click.prevent.stop="showCameraDialog(device)"
                        >
                          <v-icon size="x-small">mdi-video-account</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("devices.viewStream") }}
                    </v-tooltip>
                  </v-chip>
                </v-chip-group>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-dialog
        v-if="selectedDeviceErrorLogs"
        :model-value="true"
        max-width="700px"
        @click:outside="closeDeviceErrorLogs"
      >
        <ModalViewErrorLogs
          :id="selectedDeviceErrorLogs"
          @close="closeDeviceErrorLogs"
        />
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";

import Alert from "@/components/Alert";
import RadialProgress from "@/components/RadialProgress";
import ModalViewErrorLogs from "./ModalViewErrorLogs";
import CameraBox from "@/components/CameraBox";
import Filter from "@/components/Filter";
import DataTableFooterV2 from "@/components/DataTableFooterV2";
//import DeviceStatistics from "./deviceStatistics";

import permissions from "@/mixins/permissions";

// Constants
const DISABLED_ALL = "DISABLED_ALL";
const DISABLED_UFACE = "DISABLED_UFACE";
const DISABLED_CONTRL = "DISABLED_CONTRL";
const ROBOSTRAZH = "ROBOSTRAZH";
const UFACE = "UFACE";
const CONTRL = "CONTRL";
const OGATE = "OGATE";
const OMINI = "OMINI";

export default {
  name: "DevicesList",

  mixins: [permissions],

  components: {
    Alert,
    ModalViewErrorLogs,
    RadialProgress,
    CameraBox,
    Filter,
    DataTableFooterV2,
  },

  data() {
    return {
      selected: [],
      selectedScanDevices: [],
      syncDeviceInterval: null,

      filter: {},
      pagination: {},
      pageData: {},

      selectedDeviceErrorLogs: null,
      syncDeviceData: [],
      devicesState: [],

      isCameraShow: false,
      cameraShowDevices: [{ ip_address: "", purpose: "all", name: "", id: 1 }],
    };
  },

  watch: {
    devices(devices) {
      this.devicesState = devices;
    },
  },

  methods: {
    ...mapActions({
      getDevicesAction: "devices/get",
      syncDevice: "devices/syncDevice",
      sort: "devices/sort",
      scanDevices: "devices/scan",
      store: "device/store",
      emergency: "device/emergency",
      blocked: "device/blocked",
      disabledBiometricStatus: "device/disabledBiometricStatus",
      reboot: "device/reboot",
      remove: "devices/remove",
      getZones: "zones/getList",
      getMonitoringAction: "devices/getMonitoring",
      setAutoSyncDevice: "devices/autoSyncDevice",
      setStatePageDataAction: "stateDevices/setPageData",
      setStateFilterAction: "stateDevices/setFilter",
      setStatePaginationAction: "stateDevices/setPagination",
    }),

    radialProgressStyle(item) {
      const progress = this.syncDeviceData[item.id]?.progress;
      if (!item.isEnabled || !this.syncDeviceData[item.id]?.status) {
        return 0;
      }
      if (progress) {
        return progress;
      }
      if (this.syncDeviceData[item.id]?.status) {
        return 100;
      }
    },
    rowStyle(item) {
      const progress = this.syncDeviceData[item.id]?.progress;
      if (!item.isEnabled || !this.syncDeviceData[item.id]?.status) {
        return `max-width: 0%`;
      }
      if (progress || this.syncDeviceData[item.id]?.progressStart) {
        return `max-width: ${progress}%`;
      }
      if (this.syncDeviceData[item.id]?.status) {
        return `max-width: 100%`;
      }
    },
    startSyncDevice(item) {
      const data = {
        ...this.syncDeviceData[item],

        progress: 0,
        progressStart: true,
      };

      this.syncDeviceData[item] = data;
      this.syncDevice(item);
    },

    getDevices() {
      const params = {
        ...this.filter,
        ...this.pagination,
      };

      this.getDevicesAction(params).then((response) => {
        if (response?.data?.meta?.pagination) {
          const pagination = response.data.meta.pagination;
          this.pagination = {
            ...this.pagination,
            page: pagination.current_page,
            limit: pagination.per_page,
            total: pagination.total,
            total_items: pagination.total_items,
          };
        }
        this.devices.forEach((device) => {
          this.setSyncDevice(device.monitoring);
        });
      });
    },

    goToAdd() {
      this.$router.push({ name: this.$const.ROUTES.ADD_DEVICE });
    },

    goToEdit(id) {
      this.$router.push({
        name: this.$const.ROUTES.EDIT_DEVICE,
        params: { id },
      });
    },

    getPurposeColor(purpose, colorScheme) {
      switch (purpose) {
        case "entrance":
          return colorScheme ? "#473AD0" : "green";
        case "exit":
          return "red";
        case "duplex":
          return "blue";
        default:
          return "grey";
      }
    },

    getPurposeIcon(purpose) {
      switch (purpose) {
        case "entrance":
          return "mdi-location-enter";
        case "exit":
          return "mdi-location-exit";
        case "duplex":
          return "mdi-swap-horizontal-circle-outline";
        default:
          return "mdi-close-circle-outline";
      }
    },

    getPurposeFrom(channel) {
      return channel.source_id
        ? this.getZoneById(channel.source_id).name
        : this.$t("devices.zoneEmpty");
    },

    getPurposeTo(channel) {
      return channel.zones_id
        ? this.getZoneById(channel.zones_id).name
        : this.$t("devices.zoneEmpty");
    },

    openDeviceErrorLogs(e) {
      this.selectedDeviceErrorLogs = e.id;
    },

    closeDeviceErrorLogs() {
      this.selectedDeviceErrorLogs = null;
    },

    setSyncDevice(item) {
      const data = {
        ...this.syncDeviceData[item.id],

        time: item.time,
        personNum: item.person_num,
        status: item.status,
        doorStatus: item.gate_command,
        isBlock: item.system_state === "on" ? false : true,
        isBiometricDisabled: item.biometric_lock,
        sync_lock: item.sync_lock,
      };

      this.syncDeviceData[item.id] = data;
    },

    setLoadFeatures(item) {
      let progress = Math.round(item.proc * 100);
      if (progress > 100) progress = 100;

      const data = {
        ...this.syncDeviceData[item.id],

        progress,
        personNum:
          item.person_num || this.syncDeviceData[item.id]?.personNum || 0,
        progressStart: false,
      };

      this.syncDeviceData[item.id] = data;
    },

    connectWebSockets() {
      this.syncDeviceInterval = setInterval(() => {
        this.getMonitoringAction().then(() => {
          this.monitoringData.forEach((item) => {
            this.setSyncDevice(item);
          });
        });
      }, 10000);

      this.$emitter.on(this.$const.WS_CHANNELS.LOAD_FEATURES, (item) => {
        this.setLoadFeatures(item);
      });
    },

    disconnectedWebSockets() {
      clearInterval(this.syncDeviceInterval);
      this.$emitter.off(this.$const.WS_CHANNELS.LOAD_FEATURES);
    },

    onDragEnd(e) {
      if (e.newIndex !== e.oldIndex) {
        const id = this.devicesState[e.newIndex].id;
        let idPlace = 0;
        let isBeforeIdPlace = false;

        if (this.devicesState[e.newIndex - 1]) {
          idPlace = this.devicesState[e.newIndex - 1].id;
        } else {
          idPlace = this.devicesState[e.newIndex + 1].id;
          isBeforeIdPlace = true;
        }

        if (idPlace) {
          this.sort({ id, idPlace, isBeforeIdPlace });
        }
      }
    },

    showCameraDialog(device) {
      this.cameraShowDevices[0].ip_address = device.ip;
      this.cameraShowDevices[0].name = device.ip;
      this.cameraShowDevices[0].device_type = device.type;
      this.isCameraShow = true;
    },

    async addSelectedDevice() {
      if (this.selectedScanDevices[0]) {
        let promises = [];

        const selectedDevice = this.selectedScanDevices[0];
        const params = {
          mac: selectedDevice?.mac,
          ip: selectedDevice?.ip,
          id: selectedDevice?.id,
        };

        switch (this.selectedScanDevices[0].type) {
          case UFACE:
            this.$router.push({
              name: this.$const.ROUTES.ADD_DEVICE,
              query: { device: UFACE },
              state: {
                device: params,
              },
            });
            break;

          case CONTRL:
            this.$router.push({
              name: this.$const.ROUTES.ADD_DEVICE,
              query: { device: CONTRL },
              state: {
                device: params,
              },
            });
            break;

          default:
            promises = this.selectedScanDevices.map((device) =>
              this.addOneDevice(device.ip, device?.type)
            );
            await Promise.all(promises);
            this.selectedScanDevices = [];
            this.getDevices();
            this.scanDevices();
            break;
        }
      }
    },

    addOneDevice(ip, deviceType) {
      const data = {
        timestamp: this.$moment().unix(),
        name: ip,
        purpose: "entrance",
        ip_address: ip,
        enabled: true,
        device_type: deviceType,
        data: JSON.stringify({
          temperature_enable: "off",
          recognition_range: 2,
          control_mode: "only_led",
          card_type: "",
          pass_timeout: 2,
          system_mode: "default",
          recognition_face: 0.7,
          recognition_mask: 0.62,
          mask_enabled: false,
          show_name: "short",
          show_themp_info: false,
          show_access: false,
          antispoofing_mode: "print",
          display_mode: "static",
          autoregistration_mode: false,
          authentication_type: "face",
        }),
        channels: [
          {
            channel: 0,
            purpose: "entrance",
            zones_id: null,
            source_id: null,
            enable: true,
          },
        ],
      };

      return this.store(data);
    },

    isDisabledScanDevice(device) {
      if (!this.selectedScanDevices.includes(device)) {
        if (this.allDevicesIp.includes(device.ip)) {
          return true;
        }

        if (this.disabledScanDeviceBySelected.includes(DISABLED_ALL)) {
          return true;
        }

        if (
          this.disabledScanDeviceBySelected.includes(DISABLED_UFACE) &&
          device.type === UFACE
        )
          return true;
      }

      if (
        this.disabledScanDeviceBySelected.includes(DISABLED_CONTRL) &&
        device.type === CONTRL
      ) {
        return true;
      }

      return false;
    },

    async onChangeDoorStatus(item, index, status) {
      const doorStatus = this.syncDeviceData[item.id].doorStatus;
      const command = this.getArrayGateCommand(doorStatus, index, status);

      this.syncDeviceData[item.id] = {
        ...this.syncDeviceData[item.id],
        doorStatus: command,
      };

      await this.emergency({
        id: item.id,
        deviceName: item.name,
        command,
      });

      this.getDevices();
    },

    async onEmergency(command) {
      const promises = this.getSelectDevices
        .map((device) => {
          if (
            this.syncDeviceData[device.id].doorStatus &&
            this.syncDeviceData[device.id].doorStatus.length
          ) {
            const newCommand = this.syncDeviceData[device.id].doorStatus.map(
              () => command
            );

            this.syncDeviceData[device.id] = {
              ...this.syncDeviceData[device.id],
              doorStatus: newCommand,
            };

            return this.emergency({
              id: device.id,
              deviceName: device.name,
              command: newCommand,
            });
          }

          return undefined;
        })
        .filter((device) => device);

      await Promise.all(promises);

      this.getDevices();
    },

    async onBlocked(state) {
      const promises = this.getSelectDevices.map((device) =>
        this.blocked({ id: device.id, deviceName: device.name, state })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    onSetDefault() {
      this.onEmergency("default");
      this.onBlocked("on");
      this.onDisabledBiometricStatus(false);
    },

    async onDisabledBiometricStatus(disable) {
      const promises = this.getSelectDevices.map((device) =>
        this.disabledBiometricStatus({
          id: device.id,
          deviceName: device.name,
          disable,
        })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    async onChangeAutoSync(status = false) {
      const promises = this.getSelectDevices.map((device) =>
        this.setAutoSyncDevice({
          id: device.id,
          status: status,
        })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    async onReboot() {
      const promises = this.getSelectDevices.map((device) =>
        this.reboot({ id: device.id, deviceName: device.name })
      );

      await Promise.all(promises);

      this.getDevices();
    },

    async onDelete() {
      this.$root.$prompt({
        title: this.$t("message.Delete"),
        message: this.$t("message.confirmDelete"),
        btnAgree: this.$t("button.delete"),

        agree: async () => {
          await this.remove(
            this.getSelectDevices.map((device) => device.id)
          ).then(() => {
            this.selected = [];
          });

          this.getDevices();
          this.scanDevices();
        },
      });
    },

    getArrayGateCommand(arr, index, status) {
      const newCommand = arr.map((item, i) => {
        if (i == index) {
          return status;
        }
        return item;
      });

      return newCommand;
    },

    getDoors(item) {
      const getDoorData = (channel, index) => {
        let name = channel.name;

        if (!name) {
          if (item.channels.length > 1) {
            name = `${this.$t("devices.door")} ${index + 1}`;
          } else {
            name = this.$t("devices.door");
          }
        }

        const doorStatus = this.syncDeviceData[item.id].doorStatus;
        let status = "unavailable";

        if (doorStatus && doorStatus.length) {
          status = doorStatus[index];
        }

        const data = {
          enable: channel.enable,
          status,
          name,
        };

        return data;
      };

      if (item.type === CONTRL && item.doorsNumber === 2) {
        const doors = [];

        doors.push(
          getDoorData(
            {
              name: "",
              enable: item.channels[0].enable || item.channels[1].enable,
            },
            0
          )
        );

        doors.push(
          getDoorData(
            {
              name: "",
              enable: item.channels[2].enable || item.channels[3].enable,
            },
            1
          )
        );

        return doors;
      } else {
        return item.channels.map(getDoorData);
      }
    },

    getDoorStatusColor(status, colorScheme) {
      switch (status) {
        case "default":
          return colorScheme ? "#7165FF" : "info";
        case "open":
          return "warning";
        case "unavailable":
          return "grey darken-3";
        default:
          return "grey darken-3";
      }
    },

    rowProps(item) {
      const props = {};
      if (item.item.id && this.can(this.$const.RULES.ACCESS_LEVELS_EDIT)) {
        props.onClick = () => this.goToEdit(item.item.id);
      }

      return props;
    },

    //Действия с фильтром
    toggleMini() {
      this.setStatePageData({ isMini: !this.pageData.isMini });
    },

    //Получение состояния страницы
    getState() {
      this.pageData = { ...this.statePageData };
      this.filter = { ...this.stateFilter };
      this.pagination = { ...this.statePagination };
    },
    //Запись состояния страницы
    setStatePageData(data) {
      this.pageData = {
        ...this.pageData,
        ...data,
      };

      this.setStatePageDataAction(this.pageData);
    },
    setFilterState() {
      this.setStateFilterAction(this.filter);
    },
    setPaginationState(data) {
      this.pagination = {
        ...this.pagination,
        ...data,
      };
      this.setStatePaginationAction(this.pagination);
    },
    //Изменение пагинации
    onPagination() {
      this.setPaginationState();
      this.getDevices();
    },
    //Изменение фильтрации
    onFilter() {
      this.setFilterState();
      this.pagination.page = 1;
      this.onPagination();
    },
  },

  computed: {
    ...mapGetters({
      devices: "devices/getListFormatted",
      getZoneById: "zones/getZoneById",
    }),

    ...mapState({
      isPending: (state) => state.devices.isPending,
      isPendingScan: (state) => state.devices.isPendingScan,
      isPendingZones: (state) => state.zones.isPending,
      scannedDevices: (state) => state.devices.scannedDevices,
      allDevicesIp: (state) => state.devices.allDevicesIp,
      isPendingSort: (state) => state.devices.isPendingSort,
      errorMessage: (state) => state.devices.errorMessage,
      errorMessageDelete: (state) => state.devices.errorMessageDelete,
      errorMessageScan: (state) => state.devices.errorMessageScan,
      monitoringData: (state) => state.devices.dataMonitoring,

      statePageData: (state) => state.stateDevices.data,
      stateFilter: (state) => state.stateDevices.filter,
      statePagination: (state) => state.stateDevices.pagination,
    }),

    getSelectDevices() {
      return this.devices.filter((item) => this.selected.includes(item.id));
    },

    selectedIds() {
      return this.selected.map((item) => item.id);
    },

    headers() {
      const headers = [];

      if (this.can(this.$const.RULES.DEVICE_EDIT) && !this.isSetFilter) {
        headers.push({
          value: "drag",
          align: "start",
          width: 50,
        });
      }

      headers.push({
        value: "avatar",
      });
      headers.push({
        title: this.$t("devices.nameDevice"),
        value: "name",
      });
      headers.push({
        title: this.$t("devices.status"),
        value: "status",
      });

      if (this.can(this.$const.RULES.DEVICE_ACTIONS)) {
        headers.push({
          title: this.$t("devices.gateCommand"),
          value: "doorStatus",
        });
      }

      headers.push({
        title: this.$t("devices.purpose"),
        value: "purpose",
      });
      headers.push({
        title: this.$t("devices.updatedAt"),
        value: "updatedAt",
      });
      headers.push({
        value: "actions",
        align: "end",
        width: 90,
      });
      headers.push({
        value: "errorLogs",
        align: "end",
        width: 50,
      });
      headers.push({
        value: "progress",
        width: 0,
      });

      return headers;
    },

    isSetFilter() {
      let isSetFilter = false;

      // for (const key in this.filter) {
      //   if (Object.hasOwnProperty.call(this.filter, key)) {
      //     const element = this.filter[key];
      //     if (element.length) {
      //       isSetFilter = true;
      //       break;
      //     }
      //   }
      // }

      return isSetFilter;
    },

    disabledScanDeviceBySelected() {
      let result = "";

      if (this.selectedScanDevices[0]) {
        switch (this.selectedScanDevices[0].type) {
          case OMINI:
          case OGATE:
            result = [DISABLED_UFACE, DISABLED_CONTRL];
            break;
          case UFACE:
          case CONTRL:
            result = [DISABLED_ALL];
            break;
          default:
            break;
        }
      }

      return result;
    },
  },

  created() {
    this.connectWebSockets();
    this.getState();
    this.getDevices();
    this.scanDevices();
    this.getZones();
  },

  unmounted() {
    this.disconnectedWebSockets();
  },
};
</script>
