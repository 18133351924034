<template>
  <v-card elevation="2">
    <ImgProtected
      v-if="!isPhotoBase64 && modelValue"
      :src="`/avatar/profile/${modelValue}`"
    />
    <v-img
      v-else-if="modelValue && modelValue != 'NONE'"
      :src="modelValue"
      :aspect-ratio="aspectRatio"
    />
    <v-img v-else height="200" class="primary">
      <template #placeholder>
        <v-row class="fill-height ma-0" align="center" justify="center">
          <v-icon size="150" color="primary"> mdi-account-circle </v-icon>
        </v-row>
      </template>
    </v-img>

    <v-card-actions>
      <v-tooltip v-if="modelValue && modelValue != 'NONE'" location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            variant="text"
            color="red"
            icon="mdi-image-off-outline"
            density="comfortable"
            :ripple="false"
            @click="removePhoto"
          />
        </template>
        <span>{{ $t("user.removePhoto") }}</span>
      </v-tooltip>
      <v-spacer />
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-cctv"
            density="comfortable"
            :ripple="false"
            @click="isCameraShow = true"
          >
          </v-btn>
        </template>
        <span>{{ $t("user.takePhotoDevice") }}</span>
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-webcam"
            density="comfortable"
            :ripple="false"
            @click="isWebCamShow = true"
          />

          <div v-if="isWebCamShow">
            <v-dialog v-model="isWebCamShow" max-width="666">
              <WebCamBox @capture="setImage" />
            </v-dialog>
          </div>
        </template>
        <span>{{ $t("user.takePhotoWebCam") }}</span>
      </v-tooltip>
      <v-tooltip location="top">
        <template #activator="{ props }">
          <v-btn
            v-bind="props"
            icon="mdi-upload"
            density="comfortable"
            :ripple="false"
            @click.prevent="$refs.file.click()"
          />

          <input
            type="file"
            accept="image/*"
            ref="file"
            style="display: none"
            @change="onUploadPhoto"
          />
        </template>
        <span>{{ $t("user.uploadPhoto") }}</span>
      </v-tooltip>
    </v-card-actions>
  </v-card>

  <CameraBox
    v-model="isCameraShow"
    :devices="devices"
    :isSelectedFirstDevice="true"
    @toggle="isCameraShow = $event"
    @image="setImage"
  />
</template>

<script>
import { isBase64 } from "validator";
import { mapState, mapActions } from "vuex";

import CameraBox from "@/components/CameraBox";
import WebCamBox from "@/components/WebCamBox";
import ImgProtected from "@/components/ImgProtected";

import faceValidate from "@/mixins/faceValidate";

export default {
  components: { CameraBox, WebCamBox, ImgProtected },

  mixins: [faceValidate],

  props: {
    modelValue: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      aspectRatio: 1,
      isCameraShow: false,
      isWebCamShow: false,
    };
  },

  methods: {
    ...mapActions({
      getDevicesAction: "devices/get",
    }),

    removePhoto() {
      this.$emit("update:modelValue", "NONE");
      this.$refs.file.value = "";
    },

    setImage(e) {
      this.$emit("update:modelValue", e);
      this.isWebCamShow = false;
    },

    onUploadPhoto(e) {
      const photoBlob = e.target.files[0];
      if (photoBlob.size <= 1000 * 1000 * 5) {
        const reader = new FileReader();
        reader.readAsDataURL(photoBlob);

        reader.onload = async () => {
          this.aspectRatio = await this.getAspectRatio(reader.result);
          this.$emit("update:modelValue", reader.result);
          this.validateFace(reader.result);
        };

        reader.onerror = (error) => {
          console.error("Error: ", error);
        };
      } else {
        this.$notify({
          group: "info",
          type: "error",
          text: `${this.$t("message.photoSizeError")} 5MB`,
        });
      }
    },

    getAspectRatio(img) {
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.src = img;
        image.onload = () => {
          resolve(image.width / image.height);
        };
        image.onerror = reject;
      });
    },
  },

  computed: {
    ...mapState({
      devices: (state) => state.devices.data,
    }),

    isPhotoBase64() {
      if (this.modelValue) {
        const index = this.modelValue.indexOf(",");
        const photo = this.modelValue.slice(index + 1);

        return isBase64(photo);
      }

      return false;
    },
  },

  created() {
    this.getDevicesAction();
  },
};
</script>
