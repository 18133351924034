<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-card elevation="2" :loading="isPendingStore">
      <template v-slot:progress>
        <v-progress-linear color="primary" height="10" indeterminate />
      </template>

      <v-card-text>
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="name"
              :label="`${$t('departments.name')} *`"
              :rules="nameRules"
              color="primary"
              density="comfortable"
              hide-details="auto"
              variant="outlined"
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model.number="parentId"
              :items="departments(id)"
              :label="$t('departments.parent')"
              item-value="id"
              item-title="name"
              color="primary"
              density="comfortable"
              hide-details="auto"
              variant="outlined"
              clearable
            />
          </v-col>

          <v-col cols="12" md="4">
            <v-select
              v-model="zonesId"
              :items="zones"
              :label="$t('departments.zone')"
              multiple
              item-value="id"
              item-title="name"
              color="primary"
              hide-details="auto"
              density="comfortable"
              variant="outlined"
            />
          </v-col>
        </v-row>

        <!-- <v-row>
          <v-col>
            <v-autocomplete
              v-model="accessModes"
              multiple
              return-object
              chips
              :label="$t('access-levels.accessModes')"
              item-title="name"
              item-value="id"
              color="primary"
              density="comfortable"
              variant="outlined"
              hide-details
              :loading="isPendingGetAccessModesState"
              :items="dataAccessModesState"
            >
              <template #append-inner>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      color="grey-lighten-1"
                      variant="text"
                      density="compact"
                      icon
                      @click="getAccessModes"
                    >
                      <v-icon>mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("setting.dataUpdate") }}</span>
                </v-tooltip>
              </template>
              <template v-if="can($const.RULES.ACCESS_MODES_ADD)" #append>
                <v-tooltip location="top">
                  <template #activator="{ props }">
                    <v-btn
                      v-bind="props"
                      variant="text"
                      density="compact"
                      icon
                      color="primary"
                      @click="goToAddAccessMode"
                    >
                      <v-icon>mdi-calendar-plus-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("access-levels.addAccessMode") }}</span>
                </v-tooltip>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row> -->
      </v-card-text>

      <v-card-actions>
        <v-btn :loading="isPendingStore" @click="cancel">
          <v-icon start>mdi-cancel</v-icon>
          {{ $t("button.cancel") }}
        </v-btn>
        <v-spacer />
        <v-btn color="green" :loading="isPendingStore" @click="save">
          <v-icon start>mdi-content-save-outline</v-icon>
          {{ $t("button.save") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

import permissions from "@/mixins/permissions";

export default {
  name: "DepartmentForm",

  mixins: [permissions],

  props: {
    id: {
      type: String,
      default: "0",
    },
    data: {
      type: Object,
      default: null,
    },
    isPendingStore: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const name = this.data?.name || "";
    const parentId = this.data?.parent?.id || null;
    const zonesId = this.data?.zones_id || [];
    // const accessModes = this.data?.access_modes?.length
    //   ? this.data.access_modes
    //   : [];

    return {
      valid: true,

      zonesId,
      name,
      parentId,
      //accessModes,

      nameRules: [(v) => !!v || this.$t("message.NameIsRequired")],
    };
  },

  computed: {
    ...mapGetters({
      departments: "departments/getListExcludeById",
    }),
    ...mapState({
      zones: (state) => state.zones.list,

      //isPendingGetAccessModesState: (state) =>
      //  state.accessModes.isPendingGetAccessModes,
      //dataAccessModesState: (state) => state.accessModes.dataAccessModes,
    }),
  },

  methods: {
    ...mapActions({
      getDepartments: "departments/getList",
      getZones: "zones/getList",

      //getAccessModes: "accessModes/getAccessModes",
    }),

    cancel() {
      this.$emit("cancel");
    },

    async save() {
      let valid = false;
      await this.$refs.form.validate().then((result) => {
        if (result.valid) valid = true;
      });
      if (!valid) return;

      this.$emit("store", {
        name: this.name,
        parent_id: this.parentId,
        zones_id: this.zonesId,
        //access_modes: this.accessModes,
      });
    },

    // goToAddAccessMode() {
    //   let route = this.$router.resolve({
    //     name: this.$const.ROUTES.ADD_ACCESS_MODES,
    //   });

    //   window.open(route.href, "_blank");
    // },
  },

  created() {
    const params = {};

    if (this.id) {
      params.exclude_id_nested = this.id;
    }

    this.getDepartments(params);
    this.getZones();
    //this.getAccessModes();
  },
};
</script>
