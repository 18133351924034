<template>
  <!--Show-->
  <v-card v-if="!isEdit" :ripple="false" @click="setIsEdit">
    <v-card-title>
      <v-row>
        <v-col class="text-h6"> {{ label || name }}</v-col>
        <v-spacer />
        <v-col v-if="!isNew" cols="auto">
          <v-btn
            :ripple="false"
            density="comfortable"
            icon="mdi-delete-outline"
            color="error"
            variant="plain"
            @click.stop="deleteCard"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="6">{{ $t("object-type.field-type") }}</v-col>
        <v-col cols="6"> {{ getTypeName(type) }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">{{ $t("object-type.field-name") }}</v-col>
        <v-col cols="6"> {{ name }}</v-col>
      </v-row>
      <v-row>
        <v-col cols="6">{{ $t("object-type.require") }}</v-col>
        <v-col cols="6">{{
          required ? $t("common.yes") : $t("common.no")
        }}</v-col>
      </v-row>
      <v-row v-if="label">
        <v-col cols="6">{{ $t("object-type.field-label") }}</v-col>
        <v-col cols="6"> {{ label }} </v-col>
      </v-row>
      <v-row v-if="defaultValue">
        <v-col cols="6">{{ $t("object-type.default-value") }}</v-col>
        <v-col cols="6"> {{ defaultValue }} </v-col>
      </v-row>
      <v-row v-if="subLabel">
        <v-col cols="6">{{ $t("object-type.field-sub-label") }}</v-col>
        <v-col cols="6"> {{ subLabel }} </v-col>
      </v-row>
      <v-row v-if="subDefaultValue">
        <v-col cols="6">{{ $t("object-type.field-sub-default-value") }}</v-col>
        <v-col cols="6"> {{ subDefaultValue }} </v-col>
      </v-row>
    </v-card-text>
  </v-card>
  <!--Edit-->
  <v-card
    v-else
    :variant="isNew ? 'outlined' : undefined"
    :color="isNew ? 'success' : undefined"
  >
    <v-card-title>
      <v-row>
        <v-col class="text-h6"> {{ label || name }}</v-col>
        <v-spacer />
        <v-col v-if="!isNew" cols="auto">
          <v-btn
            :ripple="false"
            density="comfortable"
            icon="mdi-delete-outline"
            color="error"
            variant="plain"
            @click.stop="deleteCard"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-select
            v-model="typeComputed"
            :items="curFieldTypesList"
            :label="`${$t('object-type.field-type')}*`"
            :color="isNew ? 'success' : 'primary'"
            :error-messages="typeErrors"
            item-title="name"
            item-value="value"
            variant="outlined"
            density="comfortable"
            hide-details="auto"
            @update:modelValue="v$.type.$touch()"
          />
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-model="label"
            :readonly="!readOnlyLabel"
            :disabled="!showLabel"
            :label="$t('object-type.field-label')"
            variant="outlined"
            density="comfortable"
            :color="isNew ? 'success' : 'primary'"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            v-model="name"
            :readonly="!readOnlyName"
            :disabled="!showName"
            :label="`${$t('object-type.field-name')}*`"
            :color="isNew ? 'success' : 'primary'"
            :error-messages="nameErrors"
            variant="outlined"
            density="comfortable"
            hide-details="auto"
            @update:modelValue="v$.name.$touch()"
          />
        </v-col>
        <v-col v-if="showDefaultValue" cols="6">
          <v-text-field
            v-model="defaultValue"
            :label="$t('object-type.default-value')"
            variant="outlined"
            density="comfortable"
            :color="isNew ? 'success' : 'primary'"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row v-if="showDefaultAreaValue">
        <v-col>
          <v-textarea
            v-model="defaultValue"
            :label="$t('object-type.default-value')"
            variant="outlined"
            density="comfortable"
            :color="isNew ? 'success' : 'primary'"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row v-if="showSubDefaultValue || showSubLabel">
        <v-col v-if="showSubDefaultValue" cols="6">
          <v-text-field
            v-model="subDefaultValue"
            :label="$t('object-type.field-sub-default-value')"
            variant="outlined"
            density="comfortable"
            :color="isNew ? 'success' : 'primary'"
            hide-details="auto"
          />
        </v-col>
        <v-col v-if="showSubLabel" cols="6">
          <v-text-field
            v-model="subLabel"
            :label="$t('object-type.field-sub-label')"
            variant="outlined"
            density="comfortable"
            :color="isNew ? 'success' : 'primary'"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-switch
            v-model="required"
            :disabled="!readOnlyRequire"
            :label="$t('object-type.require')"
            density="comfortable"
            :color="isNew ? 'success' : 'primary'"
            hide-details="auto"
          />
        </v-col>
      </v-row>
      <template v-if="showSelectFields">
        <v-row>
          <v-col class="text-subtitle-1">
            {{ $t("object-type.select-fields") }}
          </v-col>
        </v-row>
        <v-divider class="my-3" />
        <v-row v-for="field in items" :key="field.key">
          <v-col>
            <SelectField
              v-model:isEdit="field.isEdit"
              :fieldValue="field.value"
              :allSelectItems="items ?? []"
              @cancelCard="cancelEdit(field.key)"
              @deleteField="deleteField(field.key)"
              @saveCard="editCard(field.key, $event)"
            />
          </v-col>
        </v-row>
        <v-row v-if="!isAdd">
          <v-col>
            <v-btn
              append-icon="mdi-plus"
              block
              color="success"
              variant="text"
              @click="showAddField"
            >
              {{ $t("object-type.add-select-field") }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <SelectField
              v-model:isEdit="isAdd"
              :isNew="true"
              :fieldValue="{}"
              :allSelectItems="items ?? []"
              @cancelCard="cancelAdd"
              @saveCard="addCard($event)"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="auto">
          <v-btn variant="plain" color="black" @click.stop="goBack">{{
            $t("button.cancel")
          }}</v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="auto">
          <v-btn
            :disabled="v$.$invalid"
            variant="tonal"
            flat
            color="success"
            @click.stop="saveData"
            >{{ $t("button.save") }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
import SelectField from "./SelectField";
import validator from "../validation/Card";
import ReservedField from "../utils/constants";

export default {
  mixins: [validator, ReservedField],

  components: { SelectField },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },

    isNew: {
      type: Boolean,
      default: false,
    },

    customFieldsType: {
      type: String,
      default: "object",
    },

    fieldValue: {
      type: Object,
      default: () => {},
    },

    allFields: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      isAdd: false,

      fieldTypesList: [
        {
          name: this.$t("object-type.inviteMes"),
          value: "res_invite_mes",
        },
        {
          name: this.$t("object-type.phone"),
          value: "res_phone",
        },
        {
          name: this.$t("object-type.email"),
          value: "res_email",
        },
        {
          name: this.$t("object-type.tabNum"),
          value: "res_tab_num",
        },
        {
          name: this.$t("object-type.select"),
          value: "select",
        },
        {
          name: this.$t("object-type.select_input"),
          value: "select_input",
        },
        {
          name: this.$t("object-type.text_field"),
          value: "text",
        },
      ],
    };
  },

  watch: {
    fieldValue(newValue) {
      this.getData(newValue);
    },
  },

  methods: {
    getData(newValue) {
      const objectValue = structuredClone(newValue);

      this.name = objectValue.name ?? "";
      this.type = objectValue.type;
      this.items = objectValue.items ?? [];
      this.required = objectValue.required ?? false;
      this.defaultValue = objectValue.value ?? "";
      this.label = objectValue.label ?? "";
      this.subLabel = objectValue.input_label ?? "";
      this.subDefaultValue = objectValue.input_value ?? "";
    },
    deleteCard() {
      this.$emit("deleteCard");
    },
    setIsEdit() {
      if (!this.isEdit) {
        this.$emit("update:isEdit", true);
      }
    },
    async saveData() {
      await this.v$.$validate().then((response) => console.log(response));
      if (this.v$.$invalid) return;

      const data = {
        input_label: this.subLabel,
        input_value: this.subDefaultValue,
        items: this.items,
        label: this.label,
        name: this.name,
        required: this.required,
        type: this.type,
        value: this.defaultValue,
      };

      this.$emit("saveCard", data);
    },
    goBack() {
      this.getData(this.fieldValue);
      this.cancelAdd();
      this.$emit("cancelCard");
    },

    deleteField(key) {
      const curArray = this.items.filter((object) => object.key != key);

      this.items = curArray;
    },

    cancelEdit(key) {
      const curArray = this.items.map((object) => {
        if (object.key == key) {
          const curObject = {
            ...object,
            isEdit: false,
          };
          return curObject;
        }

        return object;
      });

      this.items = curArray;
    },

    editCard(key, data) {
      const curArray = this.items.map((object) => {
        if (object.key == key) {
          const curObject = {
            ...object,
            key: data.value,
            isEdit: false,
            value: data,
          };
          return curObject;
        }

        return object;
      });

      this.items = curArray;
    },

    showAddField() {
      this.isAdd = true;
    },

    addCard(data) {
      const curObject = {
        isEdit: false,
        key: data.value,
        value: data,
      };

      if (this.items?.length) {
        this.items.push(curObject);
      } else {
        this.items = [curObject];
      }

      this.cancelAdd();
    },

    cancelAdd() {
      this.isAdd = false;
    },

    getTypeName(type) {
      const curType = this.fieldTypesList.find(
        (typeItem) => typeItem.value == type
      );
      return curType ? curType.name : "";
    },
  },

  computed: {
    curFieldTypesList() {
      const curKeys = [];

      if (this.customFieldsType == "object") {
        this.allFields.forEach((field) => {
          if (this.reservedObjectFieldTypes.includes(field.value.type)) {
            curKeys.push(field.value.type);
          }
        });
      } else {
        this.reservedObjectFieldTypes.forEach((type) => {
          curKeys.push(type);
        });
      }

      if (this.customFieldsType == "profile") {
        this.allFields.forEach((field) => {
          if (this.reservedProfileFieldTypes.includes(field.value.type)) {
            curKeys.push(field.value.type);
          }
        });
      } else {
        this.reservedProfileFieldTypes.forEach((type) => {
          curKeys.push(type);
        });
      }

      return this.fieldTypesList.filter(
        (field) => !curKeys.includes(field.value) || field.value == this.type
      );
    },

    typeComputed: {
      get() {
        return this.type;
      },
      set(newValue) {
        this.name = "";
        this.items = [];
        this.defaultValue = "";
        this.label = "";
        this.subLabel = "";
        this.subDefaultValue = "";

        switch (newValue) {
          case "res_email":
            this.name = "email";
            this.label = this.$t("object-type.email");
            break;
          case "res_phone":
            this.name = "phone";
            this.label = this.$t("object-type.phone");
            break;
          case "res_tab_num":
            this.name = "tab_num";
            this.label = this.$t("object-type.tabNum");
            break;
          case "res_invite_mes":
            this.name = "res_invite_mes";
            this.label = this.$t("object-type.inviteMes");
            break;
          default:
            break;
        }

        this.type = newValue;
      },
    },

    showName() {
      if (!this.type) return false;

      return true;
    },

    readOnlyName() {
      if (!this.type) return false;

      if (
        ["res_phone", "res_email", "res_tab_num", "res_invite_mes"].includes(
          this.type
        )
      ) {
        return false;
      }

      return true;
    },

    showDefaultValue() {
      if (!this.type) return false;

      if (
        ["res_phone", "res_email", "res_tab_num", "res_invite_mes"].includes(
          this.type
        )
      ) {
        return false;
      }

      return true;
    },

    showDefaultAreaValue() {
      if (!this.type) return false;

      if (
        [
          "res_phone",
          "res_email",
          "res_tab_num",
          "email",
          "phone",
          "text",
        ].includes(this.type)
      ) {
        return false;
      }

      return true;
    },

    showLabel() {
      if (!this.type) return false;

      return true;
    },

    readOnlyLabel() {
      if (!this.type) return false;

      if (
        ["res_phone", "res_email", "res_tab_num", "res_invite_mes"].includes(
          this.type
        )
      ) {
        return false;
      }

      return true;
    },

    showSelectFields() {
      if (!this.type) return false;

      if (
        [
          "email",
          "phone",
          "text",
          "res_phone",
          "res_email",
          "res_tab_num",
          "res_invite_mes",
        ].includes(this.type)
      ) {
        return false;
      }

      return true;
    },

    readOnlyRequire() {
      if (!this.type) return false;

      return true;
    },

    showSubLabel() {
      if (!this.type) return false;

      if (
        [
          "email",
          "phone",
          "text",
          "res_phone",
          "select",
          "res_email",
          "res_tab_num",
          "res_invite_mes",
        ].includes(this.type)
      ) {
        return false;
      }

      return true;
    },
    showSubDefaultValue() {
      if (!this.type) return false;

      if (
        [
          "email",
          "phone",
          "text",
          "res_phone",
          "select",
          "res_email",
          "res_tab_num",
          "res_invite_mes",
        ].includes(this.type)
      ) {
        return false;
      }

      return true;
    },
  },

  created() {
    this.getData(this.fieldValue);
  },
};
</script>
