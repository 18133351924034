import actions from "./actions";
import mutations from "./mutations";
import getters from "./getters";

const state = {
  isPendingGetObjectTypes: false,
  isSuccessGetObjectTypes: false,
  isFailureGetObjectTypes: false,
  errorMessageGetObjectTypes: "",

  objectTypes: [],

  isPendingGetObject: false,
  isSuccessGetObject: false,
  isFailureGetObject: false,
  errorMessageGetObject: "",

  object: {},

  isPendingAddObject: false,
  isSuccessAddObject: false,
  isFailureAddObject: false,
  errorMessageAddObject: "",

  isPendingUpdateObject: false,
  isSuccessUpdateObject: false,
  isFailureUpdateObject: false,
  errorMessageUpdateObject: "",

  isPendingGetObjectsList: false,
  isSuccessGetObjectsList: false,
  isFailureGetObjectsList: false,
  errorMessageGetObjectsList: "",

  objectsList: [],

  isPendingDeleteObject: false,
  isSuccessDeleteObject: false,
  isFailureDeleteObject: false,
  errorMessageDeleteObject: "",

  isPendingRestoreObject: false,
  isSuccessRestoreObject: false,
  isFailureRestoreObject: false,
  errorMessageRestoreObject: "",

  isPendingBlackObject: false,
  isSuccessBlackObject: false,
  isFailureBlackObject: false,
  errorMessageBlackObject: "",

  isPendingUnblackObject: false,
  isSuccessUnblackbject: false,
  isFailureUnblackObject: false,
  errorMessageUnblackObject: "",

  isPendingBlockObject: false,
  isSuccessBlockbject: false,
  isFailureBlockObject: false,
  errorMessageBlockObject: "",

  isPendingUnblockObject: false,
  isSuccessUnblockbject: false,
  isFailureUnblockObject: false,
  errorMessageUnblockObject: "",

  isPendingSetObjectAccessLevel: false,
  isSuccessSetObjectAccessLevel: false,
  isFailureSetObjectAccessLevel: false,
  errorMessageSetObjectAccessLevel: "",

  isPendingSetObjectDepartment: false,
  isSuccessSetObjectDepartment: false,
  isFailureSetObjectDepartment: false,
  errorMessageSetObjectDepartment: "",

  isPendingReportObject: false,
  isSuccessReportbject: false,
  isFailureReportObject: false,
  errorMessageReportObject: "",

  isPendingGetObjectHistory: false,
  isSuccessGetObjectHistory: false,
  isFailureGetObjectHistory: false,
  errorMessageGetObjectHistory: "",

  objectHistory: {},

  isPendingCreateObjectCabinet: false,
  isSuccessCreateObjectCabinet: false,
  isFailureCreateObjectCabinet: false,
  errorMessageCreateObjectCabinet: "",

  isPendingDeleteObjectCabinet: false,
  isSuccessDeleteObjectCabinet: false,
  isFailureDeleteObjectCabinet: false,
  errorMessageDeleteObjectCabinet: "",
};

export default {
  state,
  mutations,
  actions,
  getters,
};
