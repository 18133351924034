import { mapState, mapActions } from "vuex";
import permissions from "@/mixins/permissions";

export default {
  mixins: [permissions],

  methods: {
    ...mapActions({
      setCurPageAction: "stateSystem/setCurPage",
    }),

    routerBefore() {
      this.$router.beforeEach((to, from, next) => {
        if (!this.user.isSuccess) {
          const checkAuth = setInterval(() => {
            if (this.user.isSuccess) {
              clearInterval(checkAuth);
              const isSuperAdmin = this.user.data?.is_admin || false;
              const isSetNewPassword = !this.user.data?.password_upd;

              // Проверяем нужно ли установить новый пароль
              if (
                isSetNewPassword &&
                to.name != this.$const.ROUTES.EDIT_PERSONAL
              ) {
                return next({ name: this.$const.ROUTES.EDIT_PERSONAL });
              }

              if (!isSuperAdmin) {
                // Проверяем есть ли права доступа к странице
                if (to.meta.permissions && to.meta.permissions.length > 0) {
                  let isAllowed = this.can(to.meta.permissions);

                  if (!isAllowed)
                    return next({ name: this.$const.ROUTES.ACCESS_DENIED });
                }
              }

              return next();
            }
          }, 100);
        } else {
          const isSuperAdmin = this.user.data?.is_admin || false;
          const isSetNewPassword = !this.user.data?.password_upd;

          // Проверяем нужно ли установить новый пароль
          if (isSetNewPassword && to.name != this.$const.ROUTES.EDIT_PERSONAL) {
            return next({ name: this.$const.ROUTES.EDIT_PERSONAL });
          }

          if (!isSuperAdmin) {
            // Проверяем есть ли права доступа к странице
            if (to.meta.permissions && to.meta.permissions.length > 0) {
              let isAllowed = this.can(to.meta.permissions);

              if (!isAllowed)
                return next({ name: this.$const.ROUTES.ACCESS_DENIED });
            }
          }

          return next();
        }
      });
    },

    afterEach() {
      this.$router.afterEach((to) => {
        if (this.$auth.check()) {
          const curTo = {
            name: to.name,
            params: to.params,
          };

          this.setCurPageAction(curTo);
        }
      });
    },

    routerInit() {
      this.routerBefore();
      this.afterEach();
    },
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.routerInit();
  },
};
