export default {
  MONITORING_VIEW: "MONITORING_VIEW",

  JOURNAL_VIEW: "JOURNAL_VIEW",

  OBJECT_VIEW: "OBJECT_VIEW",
  OBJECT_ADD: "OBJECT_ADD",
  OBJECT_EDIT: "OBJECT_EDIT",
  OBJECT_DELETE: "OBJECT_DELETE",
  OBJECT_RESTORE: "OBJECT_RESTORE",
  OBJECT_BLOCKED: "OBJECT_BLOCKED",
  OBJECT_BLACKED: "OBJECT_BLACKED",
  OBJECT_BIO_INVITE_SEND: "OBJECT_BIO_INVITE_SEND",

  PROFILE_VIEW: "PROFILE_VIEW",
  PROFILE_ADD: "PROFILE_ADD",
  PROFILE_EDIT: "PROFILE_EDIT",
  PROFILE_DELETE: "PROFILE_DELETE",
  PROFILE_BLOCKED: "PROFILE_BLOCKED",
  PROFILE_BLACKED: "PROFILE_BLACKED",

  PERMIT_VIEW: "PERMIT_VIEW",
  PERMIT_ADD: "PERMIT_ADD",
  PERMIT_EDIT: "PERMIT_EDIT",
  PERMIT_DELETE: "PERMIT_DELETE",

  ACCESS_MODES_VIEW: "ACCESS_MODES_VIEW",
  ACCESS_MODES_ADD: "ACCESS_MODES_ADD",
  ACCESS_MODES_EDIT: "ACCESS_MODES_EDIT",
  ACCESS_MODES_DELETE: "ACCESS_MODES_DELETE",

  DEPARTMENT_VIEW: "DEPARTMENT_VIEW",
  DEPARTMENT_ADD: "DEPARTMENT_ADD",
  DEPARTMENT_EDIT: "DEPARTMENT_EDIT",
  DEPARTMENT_DELETE: "DEPARTMENT_DELETE",

  ACCESS_LEVELS_VIEW: "ACCESS_LEVELS_VIEW",
  ACCESS_LEVELS_ADD: "ACCESS_LEVELS_ADD",
  ACCESS_LEVELS_EDIT: "ACCESS_LEVELS_EDIT",
  ACCESS_LEVELS_DELETE: "ACCESS_LEVELS_DELETE",

  REQUEST_VIEW: "REQUEST_VIEW",
  REQUEST_ADD: "REQUEST_ADD",
  REQUEST_CONFIRM: "REQUEST_CONFIRM",
  REQUEST_CANCEL: "REQUEST_CANCEL",

  OPERATOR_VIEW: "OPERATOR_VIEW",
  OPERATOR_ADD: "OPERATOR_ADD",
  OPERATOR_EDIT: "OPERATOR_EDIT",
  OPERATOR_DELETE: "OPERATOR_DELETE",

  DEVICE_VIEW: "DEVICE_VIEW",
  DEVICE_ADD: "DEVICE_ADD",
  DEVICE_EDIT: "DEVICE_EDIT",
  DEVICE_ACTIONS: "DEVICE_ACTIONS",

  ZONE_VIEW: "ZONE_VIEW",
  ZONE_ADD: "ZONE_ADD",
  ZONE_EDIT: "ZONE_EDIT",
  ZONE_DELETE: "ZONE_DELETE",

  NOT_OWNER_RECORDS_VIEW: "NOT_OWNER_RECORDS_VIEW",
  NOT_OWNER_RECORDS_EDIT: "NOT_OWNER_RECORDS_EDIT",
  NOT_OWNER_RECORDS_DELETE: "NOT_OWNER_RECORDS_DELETE",

  SETTINGS_VIEW: "SETTINGS_VIEW",
};
