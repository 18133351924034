<template>
  <div>
    <h2 class="mb-3">{{ $t("permits.permits") }}</h2>

    <Alert v-for="(error, i) in errorMessages" :key="i" type="error">
      {{ error }}
    </Alert>

    <template v-if="!isLoadingTemplates && !isLoadingAccessLevels">
      <v-row v-if="showedPermits.length">
        <v-col v-for="permit in showedPermits" :key="permit.loopId" cols="12">
          <Card
            :objectId="objectId"
            :data="permit"
            :readonly="readonly"
            :isShowTokens="isShowTokens"
            :showDelete="showDelete"
            :showEdit="showEdit"
            :curObject="curObject"
            @cancel="onCancel"
            @save="onSave"
            @changeEdit="onChangeEdit"
          />
        </v-col>
      </v-row>

      <v-row v-if="!readonly && showAddPermit">
        <v-col>
          <v-btn color="primary" dark @click="add">
            <v-icon start> mdi-plus </v-icon>
            {{ $t("permits.addPermit") }}
          </v-btn>
        </v-col>
      </v-row>

      <v-expansion-panels v-if="hidedPermits.length" focusable class="mt-5">
        <v-expansion-panel>
          <v-expansion-panel-title
            >{{ $t("permits.disabledPermits") }} ({{
              hidedPermits.length
            }})</v-expansion-panel-title
          >
          <v-expansion-panel-text class="pt-5">
            <v-row>
              <v-col
                v-for="permit in hidedPermits"
                :key="permit.loopId"
                cols="12"
              >
                <Card
                  :objectId="objectId"
                  :data="permit"
                  :readonly="readonly"
                  :showDelete="showDelete"
                  :showEdit="showEdit"
                  @cancel="onCancel"
                  @save="onSave"
                />
              </v-col>
            </v-row>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
    </template>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { v4 as uuid } from "uuid";

import Alert from "@/components/Alert";

import Card from "./Card";

export default {
  name: "PermitList",

  emits: ["editedStatus", "updatePermits"],

  components: { Card, Alert },

  props: {
    data: {
      type: Array,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    objectId: {
      type: [Number, String],
      default: null,
    },

    errorMessages: {
      type: Array,
      default: () => [],
    },

    isShowTokens: {
      type: Boolean,
      default: true,
    },

    curObject: {
      type: Object,
      default: () => {},
    },

    showAddPermit: {
      type: Boolean,
      default: true,
    },

    showEdit: {
      type: Boolean,
      default: true,
    },

    showDelete: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      permits: [],
    };
  },

  watch: {
    isEdited(isEdited) {
      this.$emit("editedStatus", isEdited);
    },
  },

  computed: {
    ...mapState({
      isLoadingTemplates: (state) => state.permitTemplates.isLoading,
      isLoadingAccessLevels: (state) => state.accessLevels.isLoading,
    }),

    showedPermits() {
      return this.permits.filter((item) => !item.isHide);
    },

    hidedPermits() {
      return this.permits.filter((item) => item.isHide);
    },

    isEdited() {
      return this.permits.some((item) => item.isEdit);
    },
  },

  methods: {
    ...mapActions({
      getAccessLevels: "accessLevels/getTree",
    }),

    getData() {
      this.permits = this.data.map((item) => ({
        ...item,
        loopId: item.id,
        isHide: !item.is_active ? true : false,
        isEdit: item.isEdit ? true : false,
      }));
    },

    add() {
      const loopId = uuid();

      this.permits.push({
        isNew: true,
        isHide: false,
        isEdit: true,
        loopId,
        is_active: true,
        type: null,
        onepass: false,
        pass: 1,
        startDate: null,
        expireDate: null,
        permit_access: [],
        is_face_active: true,
        tokens: [],
        values: [],
      });
    },

    onCancel(id) {
      this.permits = this.permits.filter((p) => p.loopId !== id);

      this.$emit("updatePermits", this.permits);
    },

    onSave(permit) {
      const index = this.permits.findIndex((p) => p.loopId === permit.loopId);
      this.permits.splice(index, 1, permit);

      this.$emit("updatePermits", this.permits);
    },

    onChangeEdit({ id, isEdit }) {
      const index = this.permits.findIndex((p) => p.loopId === id);
      const element = {
        ...this.permits[index],
        isEdit,
      };
      this.permits[index] = element;
    },
  },

  created() {
    this.getData();
    this.getAccessLevels();
  },
};
</script>
