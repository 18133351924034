<template>
  <v-card>
    <WebCamera
      ref="webcam"
      :fullscreenState="false"
      :takePhotoButton="{ display: false }"
      :fullscreenButton="{ display: false }"
      @init="onCameraReady()"
      @photoTaken="photoTaken"
    />

    <v-card-actions>
      <v-select
        v-model="deviceId"
        :label="this.$t('imageBox.deviceForPhoto')"
        :disabled="!isCameraReady"
        :items="devices"
        item-title="label"
        item-value="deviceId"
        hide-details
        color="primary"
        density="compact"
        variant="outlined"
        @update:modelValue="changeCamera"
      />
      <v-spacer />

      <v-btn color="primary" @click="takePhoto">{{
        $t("imageBox.createPhoto")
      }}</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import WebCamera from "@/components/WebCamera";

export default {
  components: { WebCamera },

  data() {
    return {
      deviceId: null,
      devices: [],
      isCameraReady: false,
    };
  },

  methods: {
    onCameraReady() {
      this.isCameraReady = true;
      this.deviceId = this.$refs.webcam.deviceId;

      this.$refs.webcam.cameras.forEach((device) => {
        this.devices.push({ deviceId: device.deviceId, label: device.label });
      });
    },

    changeCamera(ev) {
      this.$refs.webcam.deviceId = ev;
      this.$refs.webcam.setCamera(ev);
    },

    photoTaken(data) {
      this.$emit("capture", data.image_data_url);
    },

    async takePhoto() {
      this.$refs.webcam.takePhoto();
    },
  },
};
</script>
<style>
#webcam-ui select {
  display: none !important;
}
</style>
