<template>
  <v-menu>
    <template #activator="{ props }">
      <v-btn v-bind="props" :ripple="false" variant="flat">
        {{ $t("menu.actions") }}&nbsp;
        <v-icon end> mdi-menu-down </v-icon>
      </v-btn>
    </template>
    <v-list density="comfortable">
      <template v-if="selected.length">
        <template v-if="isBlockObject">
          <v-list-item :disabled="disableBlockByFilter" @click="$emit('block')">
            <v-list-item-title>{{ $t("button.block") }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="disableUnblockByFilter"
            @click="$emit('unblock')"
          >
            <v-list-item-title>{{ $t("button.unblock") }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isBlackObject">
          <v-list-item :disabled="disableBlackByFilter" @click="$emit('black')">
            <v-list-item-title>{{
              $t("button.add_blacklist")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="disableUnblackByFilter"
            @click="$emit('unblack')"
          >
            <v-list-item-title>{{
              $t("button.remove_blacklist")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <v-list-item
          v-if="isDeleteObject"
          :disabled="disableDeleteByFilter"
          @click="$emit('remove')"
        >
          <v-list-item-title>{{ $t("button.delete") }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isRestoreObject"
          :disabled="disableRestoreByFilter"
          @click="$emit('restore')"
        >
          <v-list-item-title>{{ $t("button.restore") }}</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click="$emit('openDepartmentDialog')">
          <v-list-item-title>{{ $t("users.setDepartment") }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$emit('openAccessLevelsDialog')">
          <v-list-item-title>{{
            $t("users.setAccessLevels")
          }}</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click="$emit('report')">
          <v-list-item-title>{{ $t("button.reportUsers") }}</v-list-item-title>
        </v-list-item>
      </template>

      <template v-else>
        <template v-if="isBlockObject">
          <v-list-item
            :disabled="disableBlockByFilter"
            @click="$emit('blockByFilter')"
          >
            <v-list-item-title>{{
              $t("button.blockByFilter")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="disableUnblockByFilter"
            @click="$emit('unblockByFilter')"
          >
            <v-list-item-title>{{
              $t("button.unblockByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <template v-if="isBlackObject">
          <v-list-item
            :disabled="disableBlackByFilter"
            @click="$emit('blackByFilter')"
          >
            <v-list-item-title>{{
              $t("button.add_blacklistByFilter")
            }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            :disabled="disableUnblackByFilter"
            @click="$emit('unblackByFilter')"
          >
            <v-list-item-title>{{
              $t("button.remove_blacklistByFilter")
            }}</v-list-item-title>
          </v-list-item>

          <v-divider />
        </template>

        <v-list-item
          v-if="isDeleteObject"
          :disabled="disableDeleteByFilter"
          @click="$emit('removeByFilter')"
        >
          <v-list-item-title>{{
            $t("button.deleteByFilter")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item
          v-if="isRestoreObject"
          :disabled="disableRestoreByFilter"
          @click="$emit('restoreByFilter')"
        >
          <v-list-item-title>{{
            $t("button.restoreByFilter")
          }}</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click="$emit('openDepartmentDialog')">
          <v-list-item-title>{{
            $t("users.setDepartmentByFilter")
          }}</v-list-item-title>
        </v-list-item>

        <v-list-item @click="$emit('openAccessLevelsDialog')">
          <v-list-item-title>{{
            $t("users.setAccessLevelsByFilter")
          }}</v-list-item-title>
        </v-list-item>

        <v-divider />

        <v-list-item @click="$emit('reportByFilter')">
          <v-list-item-title>{{ $t("button.reportUsers") }}</v-list-item-title>
        </v-list-item>
      </template>
    </v-list>
  </v-menu>
</template>

<script>
import permissions from "@/mixins/permissions";

export default {
  name: "ListActions",

  mixins: [permissions],

  props: {
    selected: {
      type: Array,
      required: true,
    },

    filter: {
      type: Object,
      require: true,
    },
  },

  computed: {
    //Disabled by Filter
    disableBlockByFilter() {
      if (this.filter?.mode == "blocks" || this.filter?.mode == "deleted")
        return true;

      return false;
    },

    disableUnblockByFilter() {
      if (this.filter?.mode == "noblocks" || this.filter?.mode == "deleted")
        return true;

      return false;
    },

    disableBlackByFilter() {
      if (this.filter?.mode == "blacks" || this.filter?.mode == "deleted")
        return true;

      return false;
    },

    disableUnblackByFilter() {
      if (this.filter?.mode == "noblacks" || this.filter?.mode == "deleted")
        return true;

      return false;
    },

    disableDeleteByFilter() {
      if (this.filter?.mode == "deleted") return true;

      return false;
    },

    disableRestoreByFilter() {
      if (this.filter?.mode == "deleted") return false;

      return true;
    },

    //Rules
    isDeleteObject() {
      return this.can(this.$const.RULES.OBJECT_DELETE);
    },

    isRestoreObject() {
      return this.can(this.$const.RULES.OBJECT_RESTORE);
    },

    isBlockObject() {
      return this.can(this.$const.RULES.PROFILE_BLOCKED);
    },

    isBlackObject() {
      return this.can(this.$const.RULES.PROFILE_BLACKED);
    },
  },
};
</script>
