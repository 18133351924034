<template>
  <v-dialog v-model="modelValueComputed" max-width="600">
    <v-card
      :loading="isPendingGetAccessLevelsTreeState"
      :disabled="isPendingGetAccessLevelsTreeState"
      :title="$t('users.setAccessLevels')"
    >
      <template #loader="{ isActive }">
        <v-progress-linear
          v-if="isActive"
          color="primary"
          height="10"
          indeterminate
        />
      </template>

      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="profileType"
              :items="profileTypes"
              :label="$t('object.profile-types')"
              :placeholder="$t('object.all-profiles')"
              item-title="name"
              item-value="id"
              color="primary"
              variant="outlined"
              density="comfortable"
              hide-details="auto"
              multiple
              clearable
              chips
              closable-chips
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <el-tree
              ref="changeAccessLevelsTree"
              :data="accessLevels"
              :props="{ label: 'name' }"
              :expand-on-click-node="false"
              :default-checked-keys="accessLevel"
              node-key="id"
              check-on-click-node
              check-strictly
              show-checkbox
              @check-change="onChangeAccessLevels"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="px-6">
        <v-row class="ma-n1">
          <v-col class="pa-1" cols="auto">
            <v-btn variant="text" @click="closeModal">
              <v-icon start>mdi-close</v-icon>
              {{ $t("button.close") }}
            </v-btn>
          </v-col>
          <v-spacer />
          <v-col class="pa-1" cols="auto">
            <v-btn
              :loading="isPendingSetObjectAccessLevelState"
              color="red"
              variant="text"
              @click="onSet('del')"
            >
              <v-icon start>mdi-delete</v-icon>
              {{ $t("button.delete") }}
            </v-btn>
          </v-col>
          <v-col class="pa-1" cols="auto">
            <v-btn
              :loading="isPendingSetObjectAccessLevelState"
              color="warning"
              variant="text"
              @click="onSet('upd')"
            >
              <v-icon start>mdi-pencil</v-icon>
              {{ $t("button.replace") }}
            </v-btn>
          </v-col>
          <v-col class="pa-1" cols="auto">
            <v-btn
              color="success"
              variant="text"
              @click="onSet('add')"
              :loading="isPendingSetObjectAccessLevelState"
            >
              <v-icon start>mdi-plus</v-icon>
              {{ $t("button.add") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "SetAccessLevelDialog",

  emits: ["update:modelValue"],

  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    selectedIds: {
      type: Array,
      default: () => [],
    },
    filter: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      accessLevel: [],
      accessLevels: [],
      profileType: [],
      profileTypes: [],
    };
  },

  watch: {
    modelValue(newValue) {
      if (newValue) {
        this.onInitModal();
      } else {
        this.onCloseModal();
      }
    },
  },

  methods: {
    ...mapActions({
      resetGetAccessLevelsTreeAction: "accessLevels/resetGetAccessLevelsTree",
      getAccessLevelsTreeAction: "accessLevels/getAccessLevelsTree",

      resetSetObjectAccessLevelAction: "object/resetSetObjectAccessLevel",
      setObjectAccessLevelAction: "object/setObjectAccessLevel",

      getProfileTypesAction: "profiles/getProfilesTypes",
      resetGetProfileTypesAction: "profiles/resetGetProfilesTypes",
    }),

    getProfileTypes() {
      this.getProfileTypesAction().then(() => {
        this.profileTypes = this.profilesTypesState;
      });
    },

    onInitModal() {
      this.getAccessLevelsTree();
      this.getProfileTypes();
    },

    onCloseModal() {
      this.accessLevel = [];
      this.profileType = [];
      this.resetSetObjectAccessLevelAction();
      this.resetGetAccessLevelsTreeAction();
      this.resetGetProfileTypesAction();
    },

    closeModal() {
      this.$emit("update:modelValue", false);
    },

    getAccessLevelsTree() {
      this.getAccessLevelsTreeAction().then(() => {
        this.accessLevels = structuredClone(this.accessLevelsTreeState);
      });
    },

    onChangeAccessLevels() {
      this.accessLevel = this.$refs.changeAccessLevelsTree.getCheckedKeys();
    },

    async onSet(action) {
      const curParams = {
        accesslevels: this.accessLevel,
        profiles: this.profileType,
        action,
      };

      curParams.filter = { ids: this.selectedIds, ...this.filter };

      this.setObjectAccessLevelAction(curParams).then(() => {
        this.accessLevel = [];
        this.closeModal();
      });
    },
  },

  computed: {
    ...mapState({
      isPendingGetAccessLevelsTreeState: (state) =>
        state.accessLevels.isPendingGetAccessLevelsTree,
      isFailureGetAccessLevelsTree: (state) =>
        state.accessLevels.isFailureGetAccessLevelsTree,
      errorMessageGetAccessLevelsTree: (state) =>
        state.accessLevels.errorMessageGetAccessLevelsTree,

      accessLevelsTreeState: (state) => state.accessLevels.accessLevelsTree,

      isPendingSetObjectAccessLevelState: (state) =>
        state.object.isPendingSetObjectAccessLevel,

      profilesTypesState: (state) => state.profiles.profilesTypes,
    }),

    modelValueComputed: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
  },
};
</script>
